type Reserves = {
  r0: number
  r1: number
}

export const getFeePercentFromReserves = (startReserves: Reserves, currentReserves: Reserves) => {
  const k1 = startReserves.r0 * startReserves.r1
  const k2 = currentReserves.r0 * currentReserves.r1

  return (1 - Math.sqrt(k1) / Math.sqrt(k2)) / 100
}

import { gql } from 'urql'
import { TX_PAIR_DATA_FIELDS } from './fragments'

export const GetUserPositions = gql`
  ${TX_PAIR_DATA_FIELDS}
  query ($id: String) {
    user(id: $id) {
      id
      liquidityPositions {
        id
        liquidityTokenBalance
        pair {
          ...PairData
          totalSupply
          reserve0
          reserve1
          reserveUSD
        }
      }
    }
    liquidityPositionSnapshots(where: { user_: { id: $id } }, orderBy: block, orderDirection: desc) {
      id
      reserve0
      reserve1
    }
  }
`

export const GetUserTransactions = gql`
  ${TX_PAIR_DATA_FIELDS}
  query ($id: String) {
    user(id: $id) {
      transactions(orderBy: timestamp, orderDirection: desc) {
        id
        timestamp
        swaps {
          hash: id
          wallet: from
          pair {
            ...PairData
          }
          amountUSD
          amount0In
          amount0Out
          amount1In
          amount1Out
        }
        mints {
          hash: id
          wallet: sender
          pair {
            ...PairData
          }
          amountUSD
          amount0In: amount0
          amount1In: amount1
        }
        burns {
          hash: id
          wallet: sender
          pair {
            ...PairData
          }
          amountUSD
          amount0Out: amount0
          amount1Out: amount1
        }
      }
    }
  }
`

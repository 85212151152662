import React, { useCallback, useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import { t, Trans } from '@lingui/macro'

import { RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import TokenWarningModal from 'components/TokenWarningModal'
import { Field } from 'state/mint/actions'
import { useActiveWeb3React } from 'hooks/web3'
import TokenPairInputPanel from 'components/pools/TokenPairInputPanel'
import { TYPE, BaseCurrencyView } from 'theme'
import { useInvestmentCalculator } from 'state/pool/hooks'
import { tryParseAmount } from 'state/swap/hooks'
import { ButtonPrimary } from 'components/Button'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { Currency, CurrencyAmount, Token } from '@niifi/godzilla2-sdk'
import { useUserAddedTokens, useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import { addLiquidityAsync, DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE } from 'hooks/useAddLiquidity'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useTransactionAdder } from 'state/transactions/hooks'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { PairState, usePair } from 'hooks/usePairs'
import { useTotalSupply } from 'hooks/useTotalSupply'
import { useV2RouterContract } from 'hooks/useContract'
import { useCurrencyBalances } from 'state/wallet/hooks'
import JSBI from 'jsbi'
import { useAllTokens } from 'hooks/Tokens'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'
import { CustomSwitch } from 'components/ThemeSwitch'
import { WalletConnect } from 'components/WalletConnect'

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const ZERO = JSBI.BigInt(0)

export const BorderedRowBetween = styled(RowBetween)`
  border-top: 1px solid ${({ theme }) => theme.bg3};
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  align-items: center;
`

// TODO: extract calculations to shared folder (hooks?)
export default function PoolInvest({
  currency0,
  currency1,
  currency0Price,
  currency1Price,
  oneCurrencyIsWETH,
  useEth,
  handleUseEth,
}: {
  currency0: Currency
  currency1: Currency
  currency0Price: string
  currency1Price: string
  oneCurrencyIsWETH: boolean
  useEth: boolean
  handleUseEth: () => void
}) {
  const { account, chainId, library } = useActiveWeb3React()
  const [token0Amount, setToken0Amount] = useState('')
  const [token1Amount, setToken1Amount] = useState('')
  const [reset, setReset] = useState(false)
  const [investmentValue, setInvestmentValue] = useState(0)
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const { calculateTotalInvestment } = useInvestmentCalculator()
  const handlePairValueChange = useCallback(
    ({ token0, token1 }) => {
      setToken0Amount(token0.value)
      setToken1Amount(token1.value)
      setInvestmentValue(calculateTotalInvestment(token0.value, token1.value, currency0Price, currency1Price))
    },
    [setToken0Amount, setToken1Amount, setInvestmentValue, currency0Price, currency1Price, calculateTotalInvestment]
  )

  const handleTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE)
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()

  const activeTokens = useAllTokens()
  const userTokens = useUserAddedTokens()

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currency0 ?? undefined,
      [Field.CURRENCY_B]: currency1 ?? undefined,
    }),
    [currency0, currency1]
  )

  const independentAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => tryParseAmount(token0Amount, currencies[Field.CURRENCY_A]),
    [currencies, token0Amount]
  )

  const dependentAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => tryParseAmount(token1Amount, currencies[Field.CURRENCY_B]),
    [currencies, token1Amount]
  )

  const [pairState, pair] = usePair(currency0, currency1)
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const noLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(totalSupply && JSBI.equal(totalSupply.quotient, ZERO)) ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.quotient, ZERO) &&
        JSBI.equal(pair.reserve1.quotient, ZERO)
    )
  const router = useV2RouterContract()
  const parsedAmounts: { [field in Field]: CurrencyAmount<Currency> | undefined } = useMemo(() => {
    return {
      [Field.CURRENCY_A]: independentAmount,
      [Field.CURRENCY_B]: dependentAmount,
    }
  }, [dependentAmount, independentAmount])

  const balances = useCurrencyBalances(account ?? undefined, [
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B],
  ])
  const currencyBalances: { [field in Field]?: CurrencyAmount<Currency> } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1],
  }

  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], router?.address)
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], router?.address)

  const unknownTokens = useMemo(() => {
    const tokens = Object.values(currencies).filter(
      (curr) => curr && curr.isToken && !(curr instanceof WrappedTokenInfo)
    )

    const combinedList = userTokens.concat(Object.values(activeTokens)).map((t) => t.address)

    return tokens.filter((t) => !combinedList.includes((t as Token).address))
  }, [currencies, activeTokens, userTokens])

  let error: string | undefined
  if (!account) {
    error = t`Connect Wallet`
  }

  if (pairState === PairState.INVALID) {
    error = error ?? t`Invalid pair`
  }

  if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? t`Enter an amount`
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts

  if (currencyAAmount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
    error = t`Insufficient ${currencies[Field.CURRENCY_A]?.symbol} balance`
  }

  if (currencyBAmount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)) {
    error = t`Insufficient ${currencies[Field.CURRENCY_B]?.symbol} balance`
  }

  const addLiquidity = () => {
    addLiquidityAsync(
      account,
      addTransaction,
      allowedSlippage,
      currencies,
      currency0,
      currency1,
      deadline,
      error,
      noLiquidity,
      router,
      chainId,
      library,
      parsedAmounts,
      () => {
        setReset(true)
        setToken0Amount('0')
        setToken1Amount('0')
        setInvestmentValue(0)
      }
    )
  }

  return (
    <>
      <TokenWarningModal
        isOpen={unknownTokens.length > 0 && !dismissTokenWarning}
        tokens={unknownTokens as Token[]}
        onConfirm={handleTokenWarning}
        onDismiss={handleTokenWarning}
      />
      <UpperSection>
        <RowBetween>
          <TYPE.subHeader color="text5">
            <Trans>Amount to add</Trans>
          </TYPE.subHeader>
          <TYPE.subHeader color="text5">
            {currencyBalances[Field.CURRENCY_A] ? (
              <>
                <Trans>Balance</Trans>
                {` ${formatCurrencyAmount(currencyBalances[Field.CURRENCY_A], 4)}`} |
                {` ${formatCurrencyAmount(currencyBalances[Field.CURRENCY_B], 4)}`}
              </>
            ) : (
              ''
            )}
          </TYPE.subHeader>
        </RowBetween>
        <AutoColumn style={{ paddingTop: '1rem' }}>
          <TokenPairInputPanel
            onChange={handlePairValueChange}
            currency0={currency0}
            currency1={currency1}
            reset={reset}
            setReset={setReset}
          />
        </AutoColumn>
        <RowBetween marginTop="0.5rem">
          <TYPE.error fontSize="0.875rem" fontWeight="normal" error={true} textAlign="left">
            {error &&
              currencies[Field.CURRENCY_A] &&
              currencies[Field.CURRENCY_B] &&
              parsedAmounts[Field.CURRENCY_A] &&
              parsedAmounts[Field.CURRENCY_B] &&
              error}
          </TYPE.error>
          <TYPE.subHeader color="text5" textAlign="right" width="50%">
            {`≈ `}
            <BaseCurrencyView type="id" value={investmentValue} />
          </TYPE.subHeader>
        </RowBetween>
        {oneCurrencyIsWETH && (
          <BorderedRowBetween padding="1rem 0" marginTop="1rem">
            <TYPE.body pl={2}>
              <Trans>Wrap ETH</Trans>
            </TYPE.body>
            <CustomSwitch
              name="eth-weth toggle"
              checked={useEth}
              onClick={(e) => e.stopPropagation()}
              onChange={handleUseEth}
            />
          </BorderedRowBetween>
        )}
        {account ? (
          <>
            {!error &&
              currencies &&
              (approvalA === ApprovalState.NOT_APPROVED || approvalB === ApprovalState.NOT_APPROVED) && (
                <ButtonPrimary
                  marginTop="1rem"
                  onClick={approvalA === ApprovalState.NOT_APPROVED ? approveACallback : approveBCallback}
                >
                  <Trans>
                    Approve{' '}
                    {currencies[approvalA === ApprovalState.NOT_APPROVED ? Field.CURRENCY_A : Field.CURRENCY_B]?.symbol}
                  </Trans>
                </ButtonPrimary>
              )}

            <ButtonPrimary
              disabled={!!error || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED}
              marginTop="1rem"
              onClick={addLiquidity}
            >
              <Trans>Add Liquidity</Trans>
            </ButtonPrimary>
          </>
        ) : (
          <RowBetween marginTop="1rem">
            <WalletConnect />
          </RowBetween>
        )}
      </UpperSection>
    </>
  )
}

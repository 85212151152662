import { Currency } from '@niifi/godzilla2-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import CurrencyAvatar from 'components/CurrencyAvatar'
import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import Logo from '../Logo'
import { useAllTokens } from '../../hooks/Tokens'
import JANUSLogo from '../../assets/images/token-logos/JANUS.png'
import tokenList from '../../constants/tokenLists/niifi-default.tokenlist.json'
import uriToHttp from 'utils/uriToHttp'

const logoUrls: { [symbol: string]: string } = {
  JANUS: JANUSLogo,
}

export const getSafeNiiFiLogo = (address: string) => {
  const niiFiListToken = tokenList.tokens.find((token) => token.address === address)
  const logoUrl = niiFiListToken?.symbol && logoUrls[niiFiListToken?.symbol]
  return logoUrl
}

export const usePublicTokenLogoURL = (address: string) => {
  const allTokens = useAllTokens()
  return (
    (allTokens[address] as WrappedTokenInfo)?.logoURI ||
    `${window.__RUNTIME_CONFIG__.TOKEN_LOGO_BASE}${address}/logo.png`
  )
}

export const useTokenLogoURLSrcs = (address?: string) => {
  const allTokens = useAllTokens()
  const listLogoURI = address && (allTokens[address] as WrappedTokenInfo)?.logoURI
  return useMemo(() => {
    if (!address) return undefined
    const safeNiiFiLogo = getSafeNiiFiLogo(address)
    if (safeNiiFiLogo) return [safeNiiFiLogo]
    if (listLogoURI) return uriToHttp(listLogoURI)
    return [`${window.__RUNTIME_CONFIG__.TOKEN_LOGO_BASE}${address}/logo.png`]
  }, [address, listLogoURI])
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  size = '32px',
  style,
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const tokenUrlSrcs = useTokenLogoURLSrcs(currency?.isToken ? currency.address : undefined)

  if (currency?.symbol === 'ETH') {
    return (
      <CurrencyAvatar
        symbol={currency.symbol}
        hideSymbol={true}
        rootStyle={{ width: '32px', height: '32px', ...style }}
        iconProps={{ width: '32px', height: '32px' }}
      />
    )
  }
  if (currency?.isNative) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} {...rest} />
  }

  return (
    <StyledLogo
      size={size}
      srcs={tokenUrlSrcs || []}
      alt={`${currency?.symbol ?? 'token'} logo`}
      style={style}
      {...rest}
    />
  )
}

import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import ToggleDrawer from '../../components/Header/ToggleDrawer'
import CurrencyDropdown from 'components/Dropdowns/CurrencyDropdown'
import AppBar from 'components/AppBar'
import { DefaultCard } from 'components/Card'
import SimpleTable from 'components/Table/Simple'
import { ArrowDownGroupSvg, ArrowUpGroupSvg, BoxGroupSvg } from './vectors'
import useTheme from 'hooks/useTheme'
import useBreakpoint from 'hooks/useBreakpoint'
import {
  BodyScroller,
  CurrencySelectWrapper,
  TYPE,
  MEDIA_WIDTHS,
  BaseCurrencyView,
  RowWrapper,
  ColumnWrapper,
} from 'theme'
import { RowBetween } from 'components/Row'
import { BodyPanel } from '../styled'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import CurrencyAvatar from 'components/CurrencyAvatar'
import Percent from 'components/Percent'
import { useTokenPerformance, useNewPools, useFallbackTokens } from 'hooks/useGraph'
import { OrderDir } from 'constants/queries'

const Card = styled(DefaultCard)`
  padding: 0;
  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`
// TODO: move to shared component
export const CustomAppBar = styled(AppBar)`
  padding: 0px 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.5rem 1rem;
  `}
`
// TODO: Move to shared component
const CardHeader = styled.div`
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  padding: 1rem 2.125rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    padding: 1.25rem;
    min-width: auto;
    align-items: center;
    border-radius: 0.75rem 0.75rem 0 0;
  `}
  gap: 1rem;
  border-radius: 0.75rem 0 0 0.75rem;
  min-width: 18.75rem;
`

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.primary1};
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const LinkWrapper = styled.div`
  padding: 1rem;
  padding-left: 2.125rem;
  ${({}) =>
    ({ theme }) =>
      theme.mediaWidth.upToSmall`
    padding-left: 1.125rem;
  `}
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 0.5rem;
  `}
`

export default function Discover() {
  const theme = useTheme()
  const history = useHistory()
  const isSmallScreen = useBreakpoint(MEDIA_WIDTHS.upToSmall)

  const { data: gainersData, fetching: fetchingGainers } = useTokenPerformance({ orderDir: OrderDir.DESC })
  const { data: losersData, fetching: fetchingLosers } = useTokenPerformance({ orderDir: OrderDir.ASC })
  const { data: newPoolsData, fetching: fetchingPools } = useNewPools({})
  const { data: fallbackTokens } = useFallbackTokens()

  const gainersDataOrFallback = gainersData?.length ? gainersData : fallbackTokens || []
  const losersDataOrFallback = losersData?.length ? losersData : fallbackTokens || []

  const rowCellStyles = {
    color: theme.black,
    borderBottom: `1px solid ${theme.bg3}`,
    fontSize: '16px',
    padding: '6px 16px',
  }

  return (
    <>
      <CustomAppBar>
        <RowBetween>
          <ToggleDrawer />
        </RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CurrencySelectWrapper>
            <CurrencyDropdown />
          </CurrencySelectWrapper>
        </div>
      </CustomAppBar>
      <BodyScroller>
        <BodyPanel>
          <CardsContainer>
            <div>
              <Card>
                <CardHeader
                  style={{
                    background: `radial-gradient(at top left, ${theme.aqua1} 0%, ${theme.aqua2} 100%)`,
                  }}
                >
                  <div>
                    <TYPE.body>
                      <Trans>Trending tokens</Trans>
                    </TYPE.body>
                    <TYPE.largeHeader fontWeight={400} lineHeight="2.250rem">
                      <Trans>Top Gainers</Trans>
                    </TYPE.largeHeader>
                  </div>
                  <ArrowUpGroupSvg style={{ alignSelf: isSmallScreen ? '' : 'end' }} />
                </CardHeader>
                <div style={{ flexGrow: 1 }}>
                  {fetchingGainers || (
                    <SimpleTable
                      data={gainersDataOrFallback.slice(0, 3)}
                      row={(row, index) => {
                        const amount = row.priceUSD
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={false}
                            tabIndex={-1}
                            key={index}
                            selected={false}
                            onClick={() => window.open(row.url, '_blank')}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              style={{
                                ...rowCellStyles,
                                paddingLeft: isSmallScreen ? '1.125rem' : '2.125rem',
                                paddingRight: 0,
                                width: '5.5rem',
                              }}
                              align="center"
                            >
                              #{index + 1}
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles }} align="left">
                              <RowWrapper>
                                <CurrencyAvatar
                                  symbol={row.symbol || 'ETH'}
                                  address={row.address}
                                  iconProps={{ width: '32', height: '32' }}
                                  hideSymbol={true}
                                />
                                <TYPE.black style={{ padding: '8px 0 0 6px' }}>{row.symbol}</TYPE.black>
                              </RowWrapper>
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles }} align="right">
                              <TYPE.body color={theme.text5} fontWeight={400} fontSize={14} lineHeight={1.4}>
                                <Trans>Price</Trans>
                              </TYPE.body>
                              <TYPE.body>
                                {amount && !isNaN(amount) && (
                                  <>
                                    {'≈'}
                                    <BaseCurrencyView type="symbol" value={amount} />
                                  </>
                                )}
                              </TYPE.body>
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles, width: '8rem' }} valign="middle" align="right">
                              <Percent value={row.priceUSDChange} decimals={0} fontWeight={500} />
                            </TableCell>
                          </TableRow>
                        )
                      }}
                    />
                  )}
                  <LinkWrapper>
                    <Link
                      to={{
                        pathname: '/dashboard',
                        state: { activeTab: 0, type: 'gainer' },
                      }}
                    >
                      <Trans>See all</Trans>
                    </Link>
                  </LinkWrapper>
                </div>
              </Card>
            </div>
            <div>
              <Card>
                <CardHeader
                  style={{
                    background: `radial-gradient(at top left, ${theme.aqua2} 0%, ${theme.aqua1} 100%)`,
                  }}
                >
                  <div>
                    <TYPE.body>
                      <Trans>Trending tokens</Trans>
                    </TYPE.body>
                    <TYPE.largeHeader fontWeight={400} lineHeight="2.250rem">
                      <Trans>Top Losers</Trans>
                    </TYPE.largeHeader>
                  </div>
                  <ArrowDownGroupSvg style={{ alignSelf: isSmallScreen ? '' : 'end' }} />
                </CardHeader>
                <div style={{ flexGrow: 1 }}>
                  {fetchingLosers || (
                    <SimpleTable
                      data={losersDataOrFallback.slice(0, 3)}
                      row={(row, index) => {
                        const amount = row.priceUSD
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={false}
                            tabIndex={-1}
                            key={index}
                            selected={false}
                            onClick={() => window.open(row.url, '_blank')}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              style={{
                                ...rowCellStyles,
                                paddingLeft: isSmallScreen ? '1.125rem' : '2.125rem',
                                paddingRight: 0,
                                width: '5.5rem',
                              }}
                              align="center"
                            >
                              #{index + 1}
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles }} align="left">
                              <RowWrapper>
                                <CurrencyAvatar
                                  symbol={row.symbol || 'ETH'}
                                  address={row.address}
                                  iconProps={{ width: '32', height: '32' }}
                                  hideSymbol={true}
                                />
                                <TYPE.black style={{ padding: '8px 0 0 6px' }}>{row.symbol}</TYPE.black>
                              </RowWrapper>
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles }} align="right">
                              <TYPE.body color={theme.text5} fontWeight={400} fontSize={14} lineHeight={1.4}>
                                <Trans>Price</Trans>
                              </TYPE.body>
                              <TYPE.body>
                                {amount && !isNaN(amount) && (
                                  <>
                                    {'≈'}
                                    <BaseCurrencyView type="symbol" value={amount} />
                                  </>
                                )}
                              </TYPE.body>
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles, width: '8rem' }} valign="middle" align="right">
                              <Percent value={row.priceUSDChange} decimals={0} fontWeight={500} />
                            </TableCell>
                          </TableRow>
                        )
                      }}
                    />
                  )}
                  <LinkWrapper>
                    <Link
                      to={{
                        pathname: '/dashboard',
                        state: { activeTab: 0, type: 'looser' },
                      }}
                    >
                      <Trans>See all</Trans>
                    </Link>
                  </LinkWrapper>
                </div>
              </Card>
            </div>
            <div>
              <Card>
                <CardHeader
                  style={{
                    background: `radial-gradient(at bottom left, ${theme.aqua1} 0%, ${theme.aqua2} 100%)`,
                  }}
                >
                  <TYPE.largeHeader fontWeight={400}>
                    <Trans>New Pools</Trans>
                  </TYPE.largeHeader>
                  <BoxGroupSvg style={{ alignSelf: isSmallScreen ? '' : 'end' }} />
                </CardHeader>
                <div style={{ flexGrow: 1 }}>
                  {!fetchingPools && newPoolsData && (
                    <SimpleTable
                      data={newPoolsData}
                      row={(row, index) => {
                        const amount = row.liquidity
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={false}
                            tabIndex={-1}
                            key={index}
                            selected={false}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            <TableCell
                              style={{
                                ...rowCellStyles,
                                paddingLeft: isSmallScreen ? '1.125rem' : '2.125rem',
                              }}
                              align="left"
                              onClick={() => history.push(`/pool/${row.address}`)}
                            >
                              <RowWrapper style={{ alignItems: 'center' }}>
                                <div style={{ position: 'relative' }}>
                                  <CurrencyAvatar
                                    symbol=""
                                    address={row.token0.address}
                                    iconProps={{ width: '32', height: '32' }}
                                    containerStyle={{ zIndex: 1 }}
                                    hideSymbol={true}
                                  />
                                  <CurrencyAvatar
                                    symbol=""
                                    address={row.token1.address}
                                    iconProps={{ width: '34', height: '34' }}
                                    containerStyle={{
                                      left: '1.125rem',
                                      position: 'absolute',
                                      marginTop: '-2.125rem',
                                    }}
                                    hideSymbol={true}
                                  />
                                </div>
                                <ColumnWrapper style={{ marginLeft: '2.6rem' }}>
                                  <TYPE.body fontWeight={500} lineHeight={1.35}>
                                    {row.token0.symbol} / {row.token1.symbol}
                                  </TYPE.body>
                                </ColumnWrapper>
                              </RowWrapper>
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles }} align="right">
                              <TYPE.body color={theme.text5} fontWeight={400} fontSize={14} lineHeight={1.35}>
                                <Trans>TVL</Trans>
                              </TYPE.body>
                              <TYPE.body lineHeight={1.35}>
                                {amount && !isNaN(amount) && <BaseCurrencyView type="symbol" value={amount} />}
                              </TYPE.body>
                            </TableCell>
                            <TableCell style={{ ...rowCellStyles, width: '8rem' }} valign="middle" align="right">
                              <Percent value={row.apy} decimals={0} fontWeight={500} />
                            </TableCell>
                          </TableRow>
                        )
                      }}
                    />
                  )}
                  <LinkWrapper>
                    <Link
                      to={{
                        pathname: '/pools/search',
                      }}
                    >
                      <Trans>See all</Trans>
                    </Link>
                  </LinkWrapper>
                </div>
              </Card>
            </div>
          </CardsContainer>
        </BodyPanel>
      </BodyScroller>
    </>
  )
}

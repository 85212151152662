export interface Version {
  readonly major: number
  readonly minor: number
  readonly patch: number
}

export interface Tags {
  readonly [tagId: string]: {
    readonly name: string
    readonly description: string
  }
}

export interface TokenInfo {
  readonly chainId: number
  readonly address: string
  readonly name: string
  readonly decimals: number
  readonly symbol: string
  readonly logoURI?: string
  readonly tags?: string[]
  readonly extensions?: {
    readonly [key: string]: string | number | boolean | null
  }
}

export interface TokenList {
  readonly name: string
  readonly timestamp: string
  readonly version: Version
  readonly tokens: TokenInfo[]
  readonly keywords?: string[]
  readonly tags?: Tags
  readonly logoURI?: string
}

export enum VersionUpgrade {
  NONE = 0,
  PATCH = 1,
  MINOR = 2,
  MAJOR = 3,
}

export type TokenInfoChangeKey = Exclude<keyof TokenInfo, 'address' | 'chainId'>

export type TokenInfoChanges = Array<TokenInfoChangeKey>

export interface TokenListDiff {
  readonly added: TokenInfo[]

  readonly removed: TokenInfo[]

  readonly changed: {
    [chainId: number]: {
      [address: string]: TokenInfoChanges
    }
  }
}

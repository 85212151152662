import { Contract } from '@ethersproject/contracts'
import { useEffect, useState } from 'react'
import { useBlockNumber } from 'state/application/hooks'

export const useEIP2612Nonce = (contract: Contract | null, account: string | null | undefined) => {
  const [nonce, setNonce] = useState<number | undefined>()
  const latestBlockNumber = useBlockNumber()

  useEffect(() => {
    if (!latestBlockNumber || !contract || !account) {
      return
    }

    const getNonce = async () => {
      try {
        const nonceValue = await contract.nonces(account)
        setNonce(nonceValue.toNumber())
      } catch (error) {
        console.debug('error getting EIP2612 nonce: ', error)
      }
    }
    getNonce()
  }, [account, contract, latestBlockNumber])

  return nonce
}

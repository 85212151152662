import React from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from 'state/application/hooks'
import { Trans } from '@lingui/macro'
import { ButtonError, ButtonPrimary } from 'components/Button'
import { Activity } from 'react-feather'
import styled from 'styled-components'

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

export const WalletConnect = () => {
  const { error } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  if (error) {
    return (
      <ButtonError error={true} onClick={toggleWalletModal}>
        <span>
          <NetworkIcon />{' '}
          {error instanceof UnsupportedChainIdError ? <Trans>Wrong Network</Trans> : <Trans>Error</Trans>}
        </span>
      </ButtonError>
    )
  } else {
    return (
      <ButtonPrimary data-test="wallet-status-connect-button" onClick={toggleWalletModal}>
        <Trans>Connect Wallet</Trans>
      </ButtonPrimary>
    )
  }
}

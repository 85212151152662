import React from 'react'
import styled from 'styled-components'
import { t } from '@lingui/macro'
import { ArrowLeft, ArrowRight } from 'react-feather'
type TableToolBarProps = {
  title?: any
  currentPage: number
  hasMore: boolean
  onNext: (currentPage: number) => void
  onBack: (currentPage: number) => void
}
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  padding-bottom: 1rem;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    padding-bottom: 0;
    gap: unset;
    align-items: stretch;
  `}
`
const TitleWrapper = styled.div`
  flex-grow: 1;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  font-size: 1.25rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1rem;
  `}
`
const PagerWrapper = styled.div`
  align-self: end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.text1};
  padding: 0.8rem 0;
`

const IconButtonWrapper = styled.button`
  display: grid;
  place-content: center;
  color: ${({ theme }) => theme.text1};
  :disabled {
    cursor: default;
    color: ${({ theme }) => theme.text2};
  }

  > svg {
    width: 1rem;
    height: 1rem;
    margin: 0px 5px;
  }
`
export default function TableToolBar({ title, currentPage, hasMore, onNext, onBack }: TableToolBarProps) {
  const hasPreviousPage = currentPage > 0

  return (
    <Wrapper>
      <TitleWrapper>{title || ''}</TitleWrapper>
      <PagerWrapper>
        <IconButtonWrapper disabled={!hasPreviousPage} onClick={() => onBack(currentPage)}>
          <ArrowLeft />
        </IconButtonWrapper>
        <div>{t`Page ${currentPage + 1}`}</div>
        <IconButtonWrapper disabled={!hasMore} onClick={() => onNext(currentPage)}>
          <ArrowRight />
        </IconButtonWrapper>
      </PagerWrapper>
    </Wrapper>
  )
}

import { useMemo } from 'react'
import { RequestPolicy, useQuery } from 'urql'
import { PoolsToFarmiis, positionInRewardPools } from 'farmii-queries'
import { useWeb3React } from '@web3-react/core'

const useFarmiiSubgraphQuery = (
  query: any,
  variables: { [key: string]: any },
  pause = false,
  requestPolicy = 'cache-and-network' as RequestPolicy
) => {
  const urqlFarmiiContext = useMemo(
    () => ({
      url: window.__RUNTIME_CONFIG__.FARMII_SUBGRAPH_URL,
    }),
    []
  )
  const [result] = useQuery({
    query,
    variables,
    requestPolicy,
    pause,
    context: urqlFarmiiContext,
  })

  return result
}

export const useFarmiiRewardPools = (): { loading: boolean; data: any[] } => {
  const variables = useMemo(() => ({}), [])
  const { data, error, fetching } = useFarmiiSubgraphQuery(PoolsToFarmiis, variables)

  return useMemo(() => {
    if (error) {
      console.error(`Error getting farmii pools: ${error.message}`)
      return { loading: false, data: [] as any[] }
    }

    if (!data && fetching) return { loading: true, data: [] as any[] }

    return { loading: false, data: data.poolsToFarmiis as any[] }
  }, [data, error, fetching])
}

export const useUserFarmiiPositions = (): { loading: boolean; data: any[] } => {
  const { account: userAddress } = useWeb3React()

  const variables = useMemo(() => ({ userAddress: userAddress?.toLowerCase() }), [userAddress])

  const { data, error, fetching } = useFarmiiSubgraphQuery(userAddress ? positionInRewardPools : '', variables)

  return useMemo(() => {
    if (error) {
      console.error(`Error getting farmii pools: ${error.message}`)
      return { loading: false, data: [] as any[] }
    }

    if (!data && fetching) return { loading: true, data: [] as any[] }

    return { loading: false, data: data.positionInRewardPools as any[] }
  }, [data, error, fetching])
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { t, Trans } from '@lingui/macro'
import TableCell from '@material-ui/core/TableCell'
import styled, { DefaultTheme } from 'styled-components'
import CurrencyAvatar from 'components/CurrencyAvatar'
import { PoolIcon } from 'components/Icons'
import { TYPE, RowWrapper, ColumnWrapper, BaseCurrencyView } from 'theme'
import Table from 'components/Table'
import useTheme from 'hooks/useTheme'
import { shortenDecimalValues } from 'utils'
import { useUserPositions } from 'hooks/useGraph'

import TableRow from '@material-ui/core/TableRow'

const LogoWrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.bg3};
  }
  &:last-child {
    border-bottom: none;
  }
  td:first-child {
    padding-left: 2rem;
  }
  td:last-child {
    padding-right: 2rem;
  }
`

const CustomTableRow = (
  row: any,
  index: number,
  theme: DefaultTheme,
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void
) => {
  const rowCellStyles = {
    color: theme.black,
    borderBottom: 'none',
    fontSize: '1rem',
  }

  return (
    <StyledTableRow
      hover
      onClick={(event) => handleClick(event, row.address)}
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      key={index}
      selected={false}
    >
      <TableCell style={rowCellStyles} align="left">
        <RowWrapper>
          <div style={{ position: 'relative' }}>
            <CurrencyAvatar
              symbol={row.token0.symbol}
              address={row.token0.address}
              iconProps={{ width: '32', height: '32' }}
              containerStyle={{ zIndex: 1 }}
              hideSymbol={true}
            />
            <CurrencyAvatar
              symbol={row.token1.symbol}
              address={row.token1.address}
              iconProps={{ width: '32', height: '32' }}
              containerStyle={{ left: '22px', position: 'absolute', marginTop: '-34px' }}
              hideSymbol={true}
            />
          </div>
          <RowWrapper style={{ marginLeft: '2.6rem', alignItems: 'center' }}>
            <TYPE.body fontWeight={500} style={{ display: 'flex' }}>
              {row.token0.symbol} / {row.token1.symbol}
            </TYPE.body>
          </RowWrapper>
        </RowWrapper>
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        {shortenDecimalValues(row.token0.amount)} {row.token0.symbol} / {shortenDecimalValues(row.token1.amount)}{' '}
        {row.token1.symbol}
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="symbol" value={row.amountUSD} />
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        {shortenDecimalValues(row.token0.fee)} {row.token0.symbol} / {shortenDecimalValues(row.token1.fee)}{' '}
        {row.token1.symbol}
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="symbol" value={row.feeUSD} />
      </TableCell>
    </StyledTableRow>
  )
}

export default function Pools({ account }: { account: string }) {
  const theme = useTheme()
  const history = useHistory()

  const { data: userData } = useUserPositions({ address: account || '' })

  const handleClick = (e: React.MouseEvent<unknown>, rowId: string) => {
    e.preventDefault()
    history.push(`/pool/${rowId}`)
  }

  return (
    <>
      {userData && (
        <Table
          headCells={[
            { id: 'symbolId', numeric: false, align: 'left', disablePadding: false, label: t`Assets` },
            { id: 'token0.amount', numeric: true, disablePadding: false, label: t`Balance` },
            { id: 'amountUSD', numeric: true, disablePadding: false, label: t`Value` },
            { id: 'token0.fee', numeric: true, disablePadding: false, label: t`Fees earned` },
            { id: 'feeUSD', numeric: true, disablePadding: false, label: t`Fees total` },
          ]}
          onClick={handleClick}
          rowsPerPage={8}
          title={
            <>
              <RowWrapper style={{ gap: '1rem' }}>
                <LogoWrapper
                  style={{
                    background: `radial-gradient(at top left, ${theme.turquoise1} 0%, ${theme.purple1} 100%)`,
                  }}
                >
                  <PoolIcon color={theme.white} opacity={1} />
                </LogoWrapper>
                <ColumnWrapper style={{ justifyContent: 'center', gap: '0.125rem' }}>
                  <TYPE.body>
                    <Trans>Liquidity Pools Balance</Trans>
                  </TYPE.body>
                  <TYPE.mediumHeader>
                    <BaseCurrencyView type="symbol" value={userData.balanceUSD} />
                  </TYPE.mediumHeader>
                </ColumnWrapper>
              </RowWrapper>
            </>
          }
          data={userData.positions}
          row={CustomTableRow}
          defaultOrderBy={'amountUSD'}
          defaultOrder={'desc'}
        />
      )}
    </>
  )
}

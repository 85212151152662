import React, { useState, useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Trans } from '@lingui/macro'
import LineChart from './index'
import { TYPE, BaseCurrencyView, FlexRowWrapper } from '../../theme'
import { ButtonOutlined } from '../Button'
import SwapLineChartDropdown from '../Dropdowns/SwapLineChartDropdown'
import { usePoolChartData } from 'hooks/useGraph'
import { ChartScale } from 'types/chart'
import { AutoColumn } from 'components/Column'

const StyleButtonOutlined = styled(ButtonOutlined)`
  margin: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 8px;
  `}
`
const CustomButton = ({
  value,
  text,
  current,
  onClick,
}: {
  value: string
  text: string
  current: string
  onClick: (e: React.ChangeEvent<any>) => void
}) => {
  const theme = useContext(ThemeContext)
  return (
    <StyleButtonOutlined
      value={value}
      onClick={onClick}
      width="32px"
      padding="6px"
      style={{
        display: 'inline',
        fontSize: '12px',
        color: current === value ? theme.primary1 : theme.text2,
        borderColor: current === value ? theme.primary1 : theme.bg4,
        marginRight: '0px',
      }}
    >
      <Trans>{text}</Trans>
    </StyleButtonOutlined>
  )
}

const ControlWrapper = styled(FlexRowWrapper)`
  box-sizing: border-box;
  margin: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
`

const ButtonControlWrapper = styled(TYPE.main)`
  padding-right: 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-right: 0.5rem;
  `}
`

const PoolDetailChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  gap: 0.5rem;
`

const PoolDetailChart = ({ address, token0, token1 }: { address: string; token0: string; token1: string }) => {
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [feesHover, setFeesHover] = useState<number | undefined>()
  const [currentChartValue, setCurrentChartValue] = useState<string>('tvl')
  const [currentChartPeriod, setCurrentChartPeriod] = useState<ChartScale>('week')
  const [lineChartData, setLineChartData] = useState<any[] | undefined>([])
  const [poolAddress, setPoolAddress] = useState<string>('')

  const { data, fetching } = usePoolChartData({ address: poolAddress, range: currentChartPeriod })

  const handleChartType = (e: string): void => {
    setCurrentChartValue(e)
  }

  const handleChartPeriod = (e: React.ChangeEvent<any>): void => {
    setCurrentChartPeriod(e.target.value)
  }

  const dateFormat = currentChartPeriod === 'all' ? 'MMM' : 'd'

  useEffect(() => {
    if (address !== poolAddress) {
      setPoolAddress(address)
    }
  }, [address, poolAddress])

  useEffect(() => {
    if (fetching || !data || data.length === 0) return
    setLineChartData(data)
  }, [fetching, data])

  useEffect(() => {
    if (!liquidityHover && lineChartData && lineChartData.length) {
      setLiquidityHover(lineChartData[lineChartData.length - 1].tvl)
    }
  }, [liquidityHover, lineChartData, currentChartPeriod])

  useEffect(() => {
    if (!volumeHover && lineChartData && lineChartData.length) {
      setVolumeHover(lineChartData[lineChartData.length - 1].volume)
    }
  }, [volumeHover, lineChartData, currentChartPeriod])

  useEffect(() => {
    if (!feesHover && lineChartData && lineChartData.length) {
      setFeesHover(lineChartData[lineChartData.length - 1].fees)
    }
  }, [feesHover, lineChartData, currentChartPeriod])
  return (
    <PoolDetailChartWrapper>
      <TYPE.mediumHeaderEllipsis>
        {`${token0}-${token1} `}
        <Trans>Pair History</Trans>
      </TYPE.mediumHeaderEllipsis>
      <AutoColumn>
        <FlexRowWrapper>
          <TYPE.black fontWeight={400}>
            <Trans>TVL</Trans>
          </TYPE.black>
          <TYPE.black style={{ paddingRight: '20px' }}>
            <BaseCurrencyView type="id" numeralFormat={'0,0'} value={liquidityHover || 0} />
          </TYPE.black>
        </FlexRowWrapper>
        <FlexRowWrapper>
          <TYPE.black fontWeight={400}>
            <Trans>Volume</Trans>
          </TYPE.black>
          <TYPE.black style={{ paddingRight: '20px' }}>
            <BaseCurrencyView type="id" numeralFormat={'0,0'} value={volumeHover || 0} />
          </TYPE.black>
        </FlexRowWrapper>
        <FlexRowWrapper>
          <TYPE.black fontWeight={400}>
            <Trans>Fees</Trans>
          </TYPE.black>
          <TYPE.black style={{ paddingRight: '20px' }}>
            <BaseCurrencyView type="id" numeralFormat={'0,0'} value={feesHover || 0} />
          </TYPE.black>
        </FlexRowWrapper>
      </AutoColumn>
      <ControlWrapper>
        <SwapLineChartDropdown onItemSelect={handleChartType} selectedItem={currentChartValue} />
        <ButtonControlWrapper>
          <CustomButton value="week" text="1W" current={currentChartPeriod} onClick={handleChartPeriod} />
          <CustomButton value="month" text="1M" current={currentChartPeriod} onClick={handleChartPeriod} />
          <CustomButton value="all" text="All" current={currentChartPeriod} onClick={handleChartPeriod} />
        </ButtonControlWrapper>
      </ControlWrapper>
      {lineChartData && (
        <LineChart
          data={lineChartData}
          minHeight={158}
          value1={liquidityHover}
          setValue1={setLiquidityHover}
          value2={volumeHover}
          setValue2={setVolumeHover}
          value3={feesHover}
          setValue3={setFeesHover}
          value1Name={'tvl'}
          value2Name={'volume'}
          value3Name={'fees'}
          currentValue={currentChartValue}
          dateFormat={dateFormat}
          XAxisTickGap={100}
          YAxisTick={{ fontSize: 14 }}
          style={{ flexDirection: 'column', marginTop: '0.5rem' }}
        />
      )}
    </PoolDetailChartWrapper>
  )
}

export default PoolDetailChart

import React, { useState, useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { Trans } from '@lingui/macro'
import { TYPE, BaseCurrencyView } from '../../theme'
import BarChart from './index'
import { useDailyChartStats } from 'hooks/useGraph'
import { dateToChartDate } from 'utils/timestamps'

const OverviewChart = () => {
  const theme = useContext(ThemeContext)
  const [amount, setAmount] = useState<number | undefined>()
  const [time, setTime] = useState<string | undefined>()
  const [data, setData] = useState<any[] | undefined>([])

  const { data: stats } = useDailyChartStats({ range: 'week' })

  useEffect(() => {
    if (!stats || !stats.length) return
    setData(stats)
  }, [stats])

  useEffect(() => {
    if (!time && data && data.length) {
      setTime(dateToChartDate(data[data.length - 1].time))
    }
  }, [time, data])

  useEffect(() => {
    if (!amount && data && data.length) {
      setAmount(data[data.length - 1].volume)
    }
  }, [amount, data])

  return (
    <>
      <TYPE.subHeader fontSize="16px" style={{ margin: '12px 0 6px 0' }}>
        <Trans>Volume History 24H</Trans>
      </TYPE.subHeader>
      <TYPE.mediumHeader>
        {(amount && !isNaN(amount) && <BaseCurrencyView type="symbol" value={amount} />) || '-'}
      </TYPE.mediumHeader>
      <TYPE.body color={theme.text5} fontWeight={400} fontSize={14} lineHeight={1.4}>
        {time || '-'}
      </TYPE.body>
      {data && (
        <BarChart
          data={data}
          value={amount}
          setValue={setAmount}
          valueName={'volume'}
          label={time}
          setLabel={setTime}
          style={{ padding: 0, marginTop: '30px' }}
        />
      )}
    </>
  )
}

export default OverviewChart

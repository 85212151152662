import React, { useCallback } from 'react'
import Switch, { SwitchProps } from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import { useDarkModeManager, useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { DarkModeIcon, LightModeIcon } from '../Icons'
interface Props extends SwitchProps {
  isThemeSwitch?: boolean
  onChange: () => void
}

const SwitchWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 56px;
  height: 28px;
`
const SwitchIcon = styled.span<{ isDark?: boolean }>`
  position: absolute;
  width: fit-content;
  top: 0;
  right: ${({ isDark }) => !isDark && '5px'};
  left: ${({ isDark }) => isDark && '-5px'};
  z-index: 1;
`

const useStyles = makeStyles({
  root: {
    width: 56,
    height: 28,
    padding: 0,
  },
  switchBase: {
    padding: 4,
    '&$checked': {
      transform: 'translateX(28px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#09CF7C',
      },
    },
  },
  themeSwitchBase: {
    padding: 4,
    '&$checked': {
      transform: 'translateX(28px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.38)',
      },
      '& $thumb': {
        backgroundColor: 'rgba(6, 34, 32, 1)',
      },
    },
  },
  thumb: (isDark) => ({
    width: 20,
    height: 20,
    backgroundColor: isDark ? 'rgba(6, 34, 32, 1)' : 'rgba(255, 255, 255, 1)',
  }),
  track: (isDark) => ({
    borderRadius: 28 / 2,
    backgroundColor: isDark ? 'rgba(9, 53, 49, 1)' : '#C9F0ED',
    opacity: 1,
    transition: 'background-color border 300 cubic-bezier(0.4, 0, 0.2, 1) 0',
  }),
  checked: {},
  focusVisible: {},
})

export const CustomSwitch = (props: Props) => {
  const { checked, isThemeSwitch } = props
  const isDarkMode = useIsDarkMode()
  const classes = useStyles(isDarkMode)
  return (
    <SwitchWrapper
      onClick={(e) => {
        e.stopPropagation()
        props.onChange && props.onChange()
      }}
    >
      {checked && isThemeSwitch && (
        <SwitchIcon isDark={true}>
          <DarkModeIcon color={'rgba(6, 34, 32, 1)'} width={'32'} height="30" />
        </SwitchIcon>
      )}
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: isThemeSwitch ? classes.themeSwitchBase : classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
      {!checked && isThemeSwitch && (
        <SwitchIcon>
          <LightModeIcon color={'rgba(12, 68, 63, 0.6)'} width={'24'} height="29" />
        </SwitchIcon>
      )}
    </SwitchWrapper>
  )
}

export default function ThemeSwitch() {
  const [darkMode, toggleSetDarkMode] = useDarkModeManager()
  const handleChange = useCallback(() => {
    toggleSetDarkMode()
  }, [toggleSetDarkMode])
  return (
    <CustomSwitch
      checked={darkMode}
      onClick={(e) => e.stopPropagation()}
      onChange={handleChange}
      name="toggleDarkMode"
      isThemeSwitch={true}
    />
  )
}

import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { orderBy as lodashOrderBy, get } from 'lodash'
import { t } from '@lingui/macro'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import useTheme from 'hooks/useTheme'
import { ArrowDownIcon, ArrowUpIcon } from '../Icons'
import TableToolBar from './TableToolbar'
import { TableDataTypes } from './types'
import SearchBar from 'components/Search'
import { MIN_SEARCH_LENGTH } from 'constants/queries'
import styled from 'styled-components'

export type Order = 'asc' | 'desc'

export interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface RenderToolBarProps {
  onNext: (page: number) => void
  onBack: (page: number) => void
  currentPage: number
  hasMore: boolean
}
interface EnhancedTableProps {
  onClick?: (props: any) => unknown
  data: Array<TableDataTypes>
  headCells: HeadCell[]
  row: (row: any, index: number, handleClick: (event: React.MouseEvent<unknown>, name: string) => void) => unknown
  headCellsBefore?: (props: any) => unknown
  renderToolbar?: (props: RenderToolBarProps) => any
  searchLabel?: string
  debouncedSearchChange: (value: string) => void
  defaultOrder?: Order
  defaultOrderBy?: string
  perPage?: number
  setFetchParams?: (params: any) => void
  fetching?: boolean
}
interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell[]
  renderCells?: (headCell: HeadCell) => JSX.Element
  cellsBefore?: (props: any) => unknown
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const theme = useTheme()
  const { order, orderBy, onRequestSort, headCells, cellsBefore } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const StyledTableRow = styled(TableRow)`
    th:first-child {
      padding-left: 2rem;
    }
    th:last-child {
      padding-right: 2rem;
    }
  `

  const renderCells =
    props.renderCells ||
    ((headCell: HeadCell) => {
      return (
        <TableCell
          style={{ color: `${theme.text4}`, borderBottom: `1px solid ${theme.bg3}` }}
          key={headCell.id}
          align={'left'}
          padding={headCell.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          {headCell.label === '#' ? (
            headCell.label
          ) : (
            <TableSortLabel
              active={orderBy === headCell.id}
              style={{ color: orderBy === headCell.id ? theme.primary1 : theme.text4 }}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={() =>
                order === 'desc' ? (
                  <ArrowDownIcon
                    style={{ marginLeft: '4px' }}
                    width="12px"
                    height="12px"
                    color={orderBy === headCell.id ? theme.primary1 : ''}
                  />
                ) : (
                  <ArrowUpIcon
                    style={{ marginLeft: '4px' }}
                    width="12px"
                    height="12px"
                    color={orderBy === headCell.id ? theme.primary1 : ''}
                  />
                )
              }
            >
              {headCell.label}
            </TableSortLabel>
          )}
        </TableCell>
      )
    })

  return (
    <TableHead>
      <StyledTableRow>
        {cellsBefore && cellsBefore(props)}
        {headCells.map(renderCells)}
      </StyledTableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(() => {
  const theme = useTheme()
  return createStyles({
    table: {
      fontSize: '1rem',
      minWidth: 750,
    },
    searchWrap: {
      fontWeight: 500,
      color: theme.text1,
      flexGrow: 1,
      maxWidth: '24rem',
      ['@media (max-width: 576px)']: {
        maxWidth: 'unset',
      },
    },
    paddingsWrap: {
      padding: '0 16px 0 16px',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
})

export default function SearchableTable(props: EnhancedTableProps) {
  const { searchLabel, debouncedSearchChange, defaultOrder, defaultOrderBy, perPage, setFetchParams } = props
  const classes = useStyles()
  const tableData: TableDataTypes[] = props.data || []
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('reserveUSD')
  const [page, setPage] = React.useState(0)
  const rowsPerPage = perPage || 10
  const [query, setQuery] = React.useState<string>('')

  const localSorting = !setFetchParams

  useEffect(() => {
    if (defaultOrderBy !== undefined) {
      setOrderBy(defaultOrderBy)
      setOrder(defaultOrder || 'asc')
      setPage(0)
    }
  }, [defaultOrder, defaultOrderBy])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFetchParams &&
      setFetchParams({
        orderBy: property,
        orderDir: isAsc ? 'desc' : 'asc',
        offset: page * rowsPerPage,
        limit: rowsPerPage + 1,
      })
  }
  const handleClick = () => {
    props.onClick && props.onClick('')
  }

  const handlePaginate = (newPage: number) => {
    setPage(newPage)
    setFetchParams &&
      setFetchParams({
        orderBy: orderBy,
        orderDir: order,
        offset: newPage * rowsPerPage,
        limit: rowsPerPage + 1,
      })
  }

  const handleSearch = (value: string) => {
    if (value.length >= MIN_SEARCH_LENGTH) handlePaginate(0)
    debouncedSearchChange(value)
    setQuery(value)
  }

  const hasMore = tableData.length > (localSorting ? rowsPerPage * (page + 1) : rowsPerPage)

  const tableDataToDisplay = localSorting
    ? lodashOrderBy(
        tableData,
        function (o) {
          const dataPoint = get(o, orderBy)
          if (orderBy !== 'address') return dataPoint
          return Number(dataPoint)
        },
        [order]
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : tableData.slice(0, rowsPerPage)

  return (
    <>
      <TableToolBar
        currentPage={page}
        hasMore={!props.fetching && hasMore}
        title={
          <SearchBar
            className={classes.searchWrap}
            placeholder={searchLabel || t`Search`}
            debouncedSearchChange={handleSearch}
            initialValue={query}
            style={{ height: '3rem' }}
          />
        }
        onNext={(currentPage: number) => {
          if (!hasMore) return
          handlePaginate(currentPage + 1)
        }}
        onBack={(currentPage: number) => {
          if (currentPage === 0) return
          handlePaginate(currentPage - 1)
        }}
      />
      <TableContainer>
        <Table className={classes.table} size={'medium'} style={{ width: '100%', tableLayout: 'auto' }}>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
            headCells={props.headCells}
            cellsBefore={props.headCellsBefore}
          />
          <TableBody>{tableDataToDisplay.map((row, index) => props.row(row, index, handleClick))}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

import { ethers } from 'ethers'

export const parsePoolDailyPrice = (pool: any) => {
  const token0HighLow = pool.token0.pairDayDataBase
  const token1HighLow = pool.token1.pairDayDataQuote

  if (token0HighLow.length === 0 || token1HighLow.length === 0) {
    return {
      token0: {
        priceHigh24h: '0',
        priceLow24h: '0',
      },
      token1: {
        priceHigh24h: '0',
        priceLow24h: '0',
      },
    }
  }

  return {
    token0: {
      priceHigh24h: token1HighLow[0].token1PriceHigh24h,
      priceLow24h: token1HighLow[0].token1PriceLow24h,
    },
    token1: {
      priceHigh24h: token0HighLow[0].token0PriceHigh24h,
      priceLow24h: token0HighLow[0].token0PriceLow24h,
    },
  }
}

export const parseTransactions = (transactions: any[]) => {
  const parsed: any[] = []

  transactions.forEach((tx: any) => {
    // TODO: Will need to pack events into array and sort by date
    // Will usually only have 1
    let events: any[] = []

    if (tx.swaps) {
      tx.swaps.forEach((swap: any) => {
        events.push({
          type: 'Swap',
          hash: tx.id,
          timestamp: tx.timestamp,
          ...parseEvent(swap),
        })
      })
    }

    if (tx.mints) {
      tx.mints.forEach((mint: any) => {
        events.push({
          type: 'Mint',
          hash: tx.id,
          timestamp: tx.timestamp,
          ...parseEvent(mint),
        })
      })
    }

    if (tx.burns) {
      tx.burns.forEach((burn: any) => {
        events.push({
          type: 'Burn',
          hash: tx.id,
          timestamp: tx.timestamp,
          ...parseEvent(burn),
        })
      })
    }

    events = events.sort((a: any, b: any) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    })

    parsed.push(...events)
  })

  return parsed
}

export const parseEvent = (event: any) => {
  return {
    wallet: ethers.utils.getAddress(event.wallet),
    pool: ethers.utils.getAddress(event.pair.pool),
    amountUSD: event.amountUSD * 2, //TODO: Is this correct?
    feesUSD: '0',
    token0: {
      address: ethers.utils.getAddress(event.pair.token0.address),
      symbol: event.pair.token0.symbol,
      amountIn: event.amount0In,
      amountOut: event.amount0Out,
    },
    token1: {
      address: ethers.utils.getAddress(event.pair.token1.address),
      symbol: event.pair.token1.symbol,
      amountIn: event.amount1In,
      amountOut: event.amount1Out,
    },
  }
}

import React, { useEffect, useRef, useState } from 'react'
import useTheme from 'hooks/useTheme'
import { t, Trans } from '@lingui/macro'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled, { DefaultTheme } from 'styled-components'
import SwapTableDropdown from '../Dropdowns/SwapTableDropdown'
import { shortenAddress, shortenDecimalValues, formatTimeStamp } from '../../utils'
import { getFromTo } from '../../utils/transaction'
import { ExternalLink, BaseCurrencyView } from 'theme'
import { useAllTransactions } from 'hooks/useGraph'
import Table from './index'
import { EXPLORER_BASE } from 'constants/general'
import { useBlockNumber } from 'state/application/hooks'

function mapTransactionTypeToWords(type: string, symbol1: string, symbol2: string) {
  return {
    Swap: `Swap ${symbol1} for ${symbol2}`,
    Mint: `Add ${symbol1} and ${symbol2}`,
    Burn: `Remove ${symbol1} and ${symbol2}`,
  }[type]
}

const StyledTableRow = styled(TableRow)`
  td:first-child {
    padding-left: 2rem;
  }
  td:last-child {
    padding-right: 2rem;
  }
`

const CustomTableRow = (
  row: any,
  index: number,
  theme: DefaultTheme,
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void
) => {
  const rowCellStyles = { color: theme.black, borderBottom: `1px solid ${theme.bg3}` }

  return (
    <StyledTableRow
      hover
      onClick={(event) => handleClick(event, row.address)}
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      key={index}
      selected={false}
    >
      <TableCell style={rowCellStyles} align="left">
        <ExternalLink href={`${EXPLORER_BASE}/tx/${row.hash}`}>
          <Trans>{mapTransactionTypeToWords(row.type, row.currency0, row.currency1)}</Trans>
        </ExternalLink>
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="id" value={row.amountUSD} />
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        {shortenDecimalValues(row.amount0)} {row.currency0}
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        {shortenDecimalValues(row.amount1)} {row.currency1}
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <ExternalLink href={`${EXPLORER_BASE}/address/${row.wallet}`}>{shortenAddress(row.wallet)}</ExternalLink>
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        {formatTimeStamp(`${Number(row.timestamp) * 1000}`)}
      </TableCell>
    </StyledTableRow>
  )
}

export default function SwapTable() {
  const theme = useTheme()

  const [data, setData] = useState<any[]>([])
  const [bn, setBn] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const blockNumber = useBlockNumber()
  const { data: txData, fetching } = useAllTransactions({ polling: bn })

  const timer = useRef<NodeJS.Timeout | undefined>(undefined)

  useEffect(() => {
    return () => {
      clearTimeout(timer.current as NodeJS.Timeout)
    }
  }, [])

  // Update table with new data when block number changes
  // Delay by 5 seconds to avoid spamming the graph
  useEffect(() => {
    if (loading) return
    if (bn === 0) {
      setBn(blockNumber as number)
      return
    }

    if (bn >= (blockNumber as number)) return
    setLoading(true)

    timer.current = setTimeout(() => {
      setBn(blockNumber as number)
      clearTimeout(timer.current as NodeJS.Timeout)
      setLoading(false)
    }, 5000)
  }, [bn, blockNumber, loading])

  useEffect(() => {
    if (fetching || !txData || txData.length === 0) return
    const parsed = txData.map((item: any) => {
      const [from, to, fromValue, toValue] = getFromTo(item.type, item.token0, item.token1)
      item.currency0 = from
      item.currency1 = to
      item.amount0 = fromValue
      item.amount1 = toValue
      return item
    })

    setData(parsed)
  }, [fetching, txData])

  return (
    <>
      {data && (
        <Table
          title={t`Recent Transactions`}
          data={data}
          headCells={[
            { id: 'amountUSD', numeric: true, disablePadding: false, label: t`Total Value` },
            { id: 'amount0', numeric: true, disablePadding: false, label: t`Token Amount` },
            { id: 'amount1', numeric: true, disablePadding: false, label: t`Token Amount` },
            { id: 'wallet', numeric: false, disablePadding: false, label: t`Account` },
            { id: 'timestamp', numeric: false, disablePadding: false, label: t`Time` },
          ]}
          row={CustomTableRow}
          headCellsBefore={({ transactionType, onTransactionTypeChange }) => (
            <TableCell
              style={{ borderBottom: `1px solid ${theme.bg3}` }}
              key={'type'}
              align={'left'}
              padding={'none'}
              sortDirection={false}
            >
              <SwapTableDropdown
                selectedItem={transactionType}
                onItemSelect={(value: string) => {
                  onTransactionTypeChange(value)
                }}
              />
            </TableCell>
          )}
          defaultOrder={'desc'}
          defaultOrderBy={'timestamp'}
        />
      )}
    </>
  )
}

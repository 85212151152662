import { gql } from 'urql'
import { TOKEN_TOKEN_DATA_FIELDS } from './fragments'

export const GetAllTokens = gql`
  ${TOKEN_TOKEN_DATA_FIELDS}
  query ($limit: Int, $offset: Int, $ts: Int, $orderBy: String, $orderDir: String) {
    tokens(
      where: { tokenDayData_: { date: $ts } }
      first: $limit
      skip: $offset
      orderBy: $orderBy
      orderDirection: $orderDir
    ) {
      ...TokenData
    }
  }
`

export const GetToken = gql`
  ${TOKEN_TOKEN_DATA_FIELDS}
  query ($id: String) {
    token(id: $id) {
      ...TokenData
    }
  }
`

export const GetAllTokensSortable = gql`
  query ($limit: Int, $offset: Int, $ts: Int, $orderBy: String, $orderDir: String) {
    tokenDayDatas(first: $limit, skip: $offset, where: { date: $ts }, orderBy: $orderBy, orderDirection: $orderDir) {
      date
      priceUSD
      pricePctChange
      dailyVolumeUSD
      totalLiquidityUSD
      token {
        address: id
        symbol
      }
    }
  }
`

export const GetTokensByPerformance = gql`
  query ($limit: Int, $ts: Int, $orderDir: String) {
    tokenDayDatas(first: $limit, where: { date: $ts }, orderBy: pricePctChange, orderDirection: $orderDir) {
      priceUSD
      priceUSDChange: pricePctChange
      volumeUSD: dailyVolumeUSD
      tvlUSD: totalLiquidityUSD
      token {
        address: id
        symbol
      }
    }
  }
`

export const GetFallbackTokens = gql`
  ${TOKEN_TOKEN_DATA_FIELDS}
  query () {
    tokens(
      orderBy: tradeVolumeUSD,  
      orderDirection: "desc",
      first: 3
    ) {
      ...TokenData
    }
  }
`

export const tokensValueInEth = gql`
  query ($tokensAddresses: [String]!) {
    tokens(where: { id_in: $tokensAddresses }) {
      address: id
      valueInEth: derivedETH
    }
  }
`

export const GetEthUsdPrice = gql`
  query {
    eth: bundle(id: "1") {
      usdPrice: ethPrice
    }
  }
`

import { useMemo } from 'react'
import { RequestPolicy, useQuery } from 'urql'
import { EXPLORER_BASE } from 'constants/general'

import {
  GetAllPools,
  GetPool,
  GetToken,
  GetAllTokens,
  GetFactoryStats,
  GetFactoryChartData,
  GetTokensByPerformance,
  GetNewPools,
  GetPoolChartData,
  GetAllTransactions,
  GetSwapTransactions,
  GetMintTransactions,
  GetBurnTransactions,
  GetUserPositions,
  GetUserTransactions,
  GetAllTokensSortable,
  GetPoolByParams,
  parseTransactions,
  parsePoolDailyPrice,
  GetFallbackTokens,
  GetEthUsdPrice,
  GetPoolsByAddress,
  tokensValueInEth,
} from '../queries'
import { ethers } from 'ethers'
import { getStartRangeTimestamp, getTodayTimestamp, unixToDate } from 'utils/timestamps'
import { ChartScale } from 'types/chart'
import { ChartRange } from 'constants/chart'
import { MIN_SEARCH_LENGTH, OrderDir } from 'constants/queries'
import { getFeePercentFromReserves } from 'utils/liquidityPositions'

const useSubgraphQuery = (
  query: any,
  variables: { [key: string]: any },
  pause = false,
  requestPolicy = 'cache-and-network' as RequestPolicy
) => {
  const [result] = useQuery({
    query,
    variables,
    requestPolicy,
    pause,
  })

  return result
}

enum TransactionType {
  ALL = 'all',
  SWAP = 'swap',
  MINT = 'mint',
  BURN = 'burn',
}

type IGlobalTxQueryParams = {
  type?: TransactionType
} & IGlobalQueryParams

type IGlobalChartQueryParams = {
  range: ChartScale
} & IGlobalQueryParams

type IAddrChartQueryParams = {
  range: ChartScale
} & IAddrQueryParams

type IAddrQueryParams = {
  address: string
} & IGlobalQueryParams

type IGlobalQueryParams = {
  limit?: number
  offset?: number
  orderDir?: OrderDir
  orderBy?: string
}

export const useAllPools = (query: IGlobalQueryParams) => {
  const [queryDefaults, queryTarget] = useMemo(() => {
    const defaults = {
      limit: 10,
      offset: 0,
      orderBy: 'reserveUSD',
      orderDir: OrderDir.DESC,
      searchArg: '',
      ...query,
    }

    let queryTarget
    if (defaults.searchArg.length >= MIN_SEARCH_LENGTH) {
      queryTarget = GetPoolByParams
    } else {
      queryTarget = GetAllPools
      defaults.searchArg = ''
    }

    return [defaults, queryTarget]
  }, [query])

  // If a user is submitted a query string, pause fetching until we reach threshold
  const shouldPause = queryDefaults.searchArg !== '' && queryDefaults.searchArg.length < MIN_SEARCH_LENGTH
  const result = useSubgraphQuery(queryTarget, queryDefaults, shouldPause, 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching all pools ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.pairs) return result

    result.data = data.pairs.map((pair: any) => {
      return {
        ...pair,
        address: ethers.utils.getAddress(pair.address),
        token0: {
          ...pair.token0,
          address: ethers.utils.getAddress(pair.token0.address),
          price: pair.token0Price,
        },
        token1: {
          ...pair.token1,
          address: ethers.utils.getAddress(pair.token1.address),
          price: pair.token1Price,
        },
      }
    })

    return result
  }, [result])
}

export const usePool = (query: IAddrQueryParams) => {
  const result = useSubgraphQuery(
    GetPool,
    {
      id: query.address.toLowerCase(),
    },
    !ethers.utils.isAddress(query.address)
  )

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching pool ${query.address} - ${error.message}`)
      return result
    }

    if (fetching || !data.pair) return result
    if (!data) {
      return {
        ...result,
        error: true,
      }
    }

    const dailyPriceData = parsePoolDailyPrice(data.pair)

    result.data = {
      ...data.pair,
      liquidity: data.pair.liquidity,
      address: ethers.utils.getAddress(data.pair.address),
      token0: {
        ...data.pair.token0,
        address: ethers.utils.getAddress(data.pair.token0.address),
        price: data.pair.token0Price,
        priceHigh24hUSD: dailyPriceData.token0.priceHigh24h,
        priceLow24hUSD: dailyPriceData.token0.priceLow24h,
      },
      token1: {
        ...data.pair.token1,
        address: ethers.utils.getAddress(data.pair.token1.address),
        price: data.pair.token1Price,
        priceHigh24hUSD: dailyPriceData.token1.priceHigh24h,
        priceLow24hUSD: dailyPriceData.token1.priceLow24h,
      },
    }

    return result
  }, [result, query.address])
}

export const useNewPools = (query: IGlobalQueryParams) => {
  const queryDefaults: IGlobalQueryParams = useMemo(() => {
    return {
      limit: 3,
      offset: 0,
      ...query,
    }
  }, [query])

  const result = useSubgraphQuery(GetNewPools, queryDefaults, false, 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching new pools ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.pairs) return result

    result.data = data.pairs.map((pair: any) => {
      return {
        ...pair,
        address: ethers.utils.getAddress(pair.address),
        token0: {
          ...pair.token0,
          address: ethers.utils.getAddress(pair.token0.address),
          price: pair.token0Price,
        },
        token1: {
          ...pair.token1,
          address: ethers.utils.getAddress(pair.token1.address),
          price: pair.token1Price,
        },
      }
    })

    return result
  }, [result])
}

export const usePoolChartData = (query: IAddrChartQueryParams) => {
  const chartQuery = useMemo(() => {
    return {
      ts: getStartRangeTimestamp(ChartRange[query.range]),
      id: query.address.toLowerCase(),
    }
  }, [query.range, query.address])

  const result = useSubgraphQuery(GetPoolChartData, chartQuery, !ethers.utils.isAddress(query.address), 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching pool chart data - ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.pairDayDatas) return result

    const parsed = data.pairDayDatas.map((dayData: any) => {
      return {
        tvl: Number(dayData.tvl),
        volume: Number(dayData.volume),
        time: unixToDate(dayData.date),
        fees: Number(dayData.fees),
      }
    })

    result.data = parsed

    return result
  }, [result])
}

export const useToken = (query: IAddrQueryParams) => {
  const result = useSubgraphQuery(
    GetToken,
    {
      id: query.address.toLowerCase(),
    },
    !ethers.utils.isAddress(query.address)
  )

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching all pools ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.token) return result

    const ckAddr = ethers.utils.getAddress(data.token.address)
    const tvlUSD = Number(data.token.tokenDayData[0].tvlUSD).toFixed(2)

    const priceUSDChange =
      Number(data.token.tokenDayData[0].date) === getTodayTimestamp() ? data.token.tokenDayData[0].priceUSDChange : '0'

    result.data = {
      address: ckAddr,
      symbol: data.token.symbol,
      url: `${EXPLORER_BASE}/tokens/${ckAddr}`,
      volumeUSD: data.token.volumeUSD,
      priceUSDChange: priceUSDChange,
      priceUSD: data.token.tokenDayData[0].priceUSD,
      tvlUSD,
    }

    return result
  }, [result])
}

export const useAllTokens = (query: IGlobalQueryParams) => {
  const [queryDefaults, queryTarget, queryPath] = useMemo(() => {
    const defaults = {
      limit: 10,
      offset: 0,
      orderBy: 'symbol',
      orderDir: OrderDir.ASC,
      ts: getTodayTimestamp(),
      ...query,
    }

    let queryTarget
    let queryPath: string

    // Swtch queries depending on sorting method
    if (query.orderBy === 'symbol' || query.orderBy === 'id') {
      queryTarget = GetAllTokens
      queryPath = 'tokens'
    } else {
      queryTarget = GetAllTokensSortable
      queryPath = 'tokenDayDatas'
    }

    return [defaults, queryTarget, queryPath]
  }, [query])

  const result = useSubgraphQuery(queryTarget, queryDefaults, false, 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching all tokens ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data[queryPath]) return result

    if (queryPath === 'tokenDayDatas') {
      result.data = data.tokenDayDatas.map((token: any) => {
        const ckAddr = ethers.utils.getAddress(token.token.address)
        const tvlUSD = Number(token.totalLiquidityUSD).toFixed(2)

        const priceUSDChange = Number(token.date) === getTodayTimestamp() ? token.pricePctChange : '0'

        return {
          address: ckAddr,
          symbol: token.token.symbol,
          url: `${EXPLORER_BASE}/tokens/${ckAddr}`,
          volumeUSD: token.dailyVolumeUSD,
          priceUSDChange: priceUSDChange,
          priceUSD: token.priceUSD,
          tvlUSD,
        }
      })
    } else {
      result.data = data.tokens.map((token: any) => {
        const ckAddr = ethers.utils.getAddress(token.address)
        const tvlUSD = Number(token.tokenDayData[0].tvlUSD).toFixed(2)

        const priceUSDChange =
          Number(token.tokenDayData[0].date) === getTodayTimestamp() ? token.tokenDayData[0].priceUSDChange : '0'

        return {
          address: ckAddr,
          symbol: token.symbol,
          url: `${EXPLORER_BASE}/tokens/${ckAddr}`,
          volumeUSD: token.volumeUSD,
          priceUSDChange: priceUSDChange,
          priceUSD: token.tokenDayData[0].priceUSD,
          tvlUSD,
        }
      })
    }

    return result
  }, [queryPath, result])
}

export const useFallbackTokens = () => {
  const result = useSubgraphQuery(GetFallbackTokens, {}, false, 'cache-first')
  return useMemo(() => {
    return {
      ...result,
      data: (result.data?.tokens || []).map((token: any) => {
        const ckAddr = ethers.utils.getAddress(token.address)
        const latestDayData = token.tokenDayData[0]
        return {
          address: ckAddr,
          symbol: token.symbol,
          url: `${EXPLORER_BASE}/tokens/${ckAddr}`,
          volumeUSD: token.dailyVolumeUSD,
          priceUSDChange: latestDayData.priceUSDChange,
          priceUSD: latestDayData.priceUSD,
          tvlUSD: latestDayData.tvlUSD,
        }
      }),
    }
  }, [result])
}

export const useDailyStats = (query: IGlobalQueryParams) => {
  const queryDefaults = useMemo(() => {
    return {
      limit: 30,
      ...query,
    }
  }, [query])

  const result = useSubgraphQuery(GetFactoryStats, queryDefaults, false, 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching daily stats ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.niifiDayDatas) return result

    result.data = data.niifiDayDatas.map((dayData: any) => {
      return {
        time: unixToDate(dayData.date),
        tvl: Number(dayData.tvl),
        volume: Number(dayData.volume),
        fees: Number(dayData.fees),
      }
    })

    return result
  }, [result])
}

export const useDailyChartStats = (query: IGlobalChartQueryParams) => {
  const chartQuery = useMemo(() => {
    return {
      ts: getStartRangeTimestamp(ChartRange[query.range]),
    }
  }, [query.range])

  const result = useSubgraphQuery(GetFactoryChartData, chartQuery, false, 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching daily stats ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.niifiDayDatas) return result

    result.data = data.niifiDayDatas.map((dayData: any) => {
      return {
        tvl: Number(dayData.tvl),
        volume: Number(dayData.volume),
        time: unixToDate(dayData.date),
        fees: Number(dayData.fees),
      }
    })

    return result
  }, [result])
}

export const useTokenPerformance = (query: IGlobalQueryParams) => {
  const queryDefaults = useMemo(() => {
    const ts = getTodayTimestamp()
    return {
      ts,
      limit: 3,
      ...query,
    }
  }, [query])

  const result = useSubgraphQuery(GetTokensByPerformance, queryDefaults, false, 'cache-first')

  return useMemo(() => {
    const { data, fetching, error } = result
    if (error) {
      console.error(`Error fetching token performance ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.tokenDayDatas) return result

    result.data = data.tokenDayDatas.map((dayData: any) => {
      const { priceUSD, priceUSDChange, volumeUSD, tvlUSD } = dayData
      const { symbol, address } = dayData.token

      const ckAddr = ethers.utils.getAddress(address)

      return {
        priceUSD,
        priceUSDChange,
        volumeUSD,
        tvlUSD,
        address: ckAddr,
        symbol,
        url: `${EXPLORER_BASE}/tokens/${ckAddr}`,
      }
    })

    return result
  }, [result])
}

export const useAllTransactions = (query: IGlobalTxQueryParams & { polling: number }) => {
  const [queryDefaults, queryTarget] = useMemo(() => {
    const defaults = {
      limit: 100,
      offset: 0,
      type: 'all',
      ...query,
    }

    let queryTarget
    switch (defaults.type) {
      case TransactionType.SWAP:
        queryTarget = GetSwapTransactions
        break
      case TransactionType.MINT:
        queryTarget = GetMintTransactions
        break
      case TransactionType.BURN:
        queryTarget = GetBurnTransactions
        break
      default:
        queryTarget = GetAllTransactions
    }

    return [defaults, queryTarget]
  }, [query])

  const result = useSubgraphQuery(queryTarget, queryDefaults)

  return useMemo(() => {
    const { data, fetching, error } = result
    if (error) {
      console.error(`Error fetching all transactions ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.transactions) return result

    const transactions = parseTransactions(data.transactions)

    result.data = transactions
    return result
  }, [result])
}

export const useUserTransactions = (query: IAddrQueryParams) => {
  const result = useSubgraphQuery(
    GetUserTransactions,
    { id: query.address.toLowerCase() },
    !ethers.utils.isAddress(query.address)
  )

  return useMemo(() => {
    const { data, fetching, error } = result
    if (error) {
      console.error(`Error fetching user transactions ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || !data.user) return result
    if (data.user === null) {
      result.data = []
      return result
    }

    const transactions = parseTransactions(data.user.transactions)

    result.data = transactions
    return result
  }, [result])
}

export const useUserPositions = (query: IAddrQueryParams) => {
  const queryDefaults = {
    limit: 8,
    ...query,
    id: query.address.toLowerCase(),
  }

  const result = useSubgraphQuery(GetUserPositions, queryDefaults, !ethers.utils.isAddress(query.address))

  return useMemo(() => {
    const { data, fetching, error } = result

    if (error) {
      console.error(`Error fetching user positions ${error.message}`)
      return result
    }

    if (fetching) return result
    if (!data || data.positions) return result
    if (data.user === null) {
      result.data = { balanceUSD: 0, positions: [] }
      return result
    }

    const positions: any[] = []

    let totalBalance = 0

    data.user.liquidityPositions.forEach((position: any) => {
      const ratioOwned = (position.liquidityTokenBalance * 0.997) / position.pair.totalSupply
      const token0Amount = position.pair.reserve0 * ratioOwned
      const token1Amount = position.pair.reserve1 * ratioOwned
      const amountUSD = position.pair.reserveUSD * ratioOwned

      totalBalance += amountUSD

      const liquiditySnapshot = data.liquidityPositionSnapshots.find((snapshot: any) => {
        return snapshot.id.includes(position.id)
      })

      const feePct = getFeePercentFromReserves(
        { r0: liquiditySnapshot.reserve0, r1: liquiditySnapshot.reserve1 },
        { r0: position.pair.reserve0, r1: position.pair.reserve1 }
      )

      positions.push({
        symbolId: position.pair.symbolId,
        address: ethers.utils.getAddress(position.pair.pool),
        token0: {
          address: ethers.utils.getAddress(position.pair.token0.address),
          symbol: position.pair.token0.symbol,
          amount: token0Amount,
          fee: token0Amount * feePct,
        },
        token1: {
          address: ethers.utils.getAddress(position.pair.token1.address),
          symbol: position.pair.token1.symbol,
          amount: token1Amount,
          fee: token1Amount * feePct,
        },
        amountUSD,
        feeUSD: amountUSD * feePct,
      })
    })

    result.data = { balanceUSD: totalBalance, positions }
    return result
  }, [result])
}

export const useEthPrice = () => {
  const variables = useMemo(() => ({}), [])
  const { data, error, fetching } = useSubgraphQuery(GetEthUsdPrice, variables)
  return useMemo(() => {
    if (error) {
      console.error(`Error getting Eth price: ${error.message}`)
      return { loading: false, data: 0 }
    }

    if (!data && fetching) return { loading: true, data: 0 }

    return { loading: false, data: Number(data.eth.usdPrice) }
  }, [data, error, fetching])
}

export const usePoolsByAddresses = (addresses: string[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const variables = useMemo(() => ({ pairsAddresses: addresses }), [JSON.stringify(addresses)])
  const { data, error, fetching } = useSubgraphQuery(GetPoolsByAddress, variables)

  return useMemo(() => {
    if (error) {
      console.error(`Error getting pairs data: ${error.message}`)
      return { loading: false, data: [] as any[] }
    }

    if (!data && fetching) return { loading: true, data: [] as any[] }

    return { loading: false, data: data.pairs as any[] }
  }, [data, error, fetching])
}

export const useTokensEthValue = (addresses: string[]) => {
  const variables = useMemo(
    () => ({ tokensAddresses: addresses.map((x) => x.toLowerCase()) }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(addresses)]
  )
  const { data, error, fetching } = useSubgraphQuery(tokensValueInEth, variables)

  return useMemo(() => {
    if (error) {
      console.error(`Error getting tokens price: ${error.message}`)
      return { loading: false, data: [] as any[] }
    }

    if (!data && fetching) return { loading: true, data: [] as any[] }

    return { loading: false, data: data.tokens as any[] }
  }, [data, error, fetching])
}

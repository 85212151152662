import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Trans } from '@lingui/macro'

import { useActiveWeb3React } from 'hooks/web3'
import { ApplicationModal } from 'state/application/actions'
import { ReactComponent as Close } from 'assets/images/x.svg'
import { ReactComponent as CheckCircle } from 'assets/svg/large-check.svg'
import { useModalOpen, useSuggestStakeFarmiiModalToggle } from 'state/application/hooks'
import Row from 'components/Row'
import { Text } from 'rebass'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import Modal from '../Modal'
import Column from 'components/Column'
import { ExternalLink } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { useLatestConfirmedAddLiquidityTransaction } from 'state/transactions/hooks'
import { FARMII_URL } from 'constants/general'
import { useFarms } from 'state/pools/hooks'

const CloseIcon = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    right: 1.5rem;
    top: 1.5rem;
  `}
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  position: relative;
  padding: 2rem;
  padding-top: 4rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1.5rem;
    padding-top: 4rem;
  `}
  ${({ theme }) => theme.flexColumnNoWrap}
  align-items: center;
  gap: 2rem;
  margin: 0;
  width: 100%;
`

const GapRow = styled(Row)`
  gap: 1rem;
`

const GapColumn = styled(Column)`
  gap: 1rem;
  align-items: center;
`

export default function SuggestStakeModal() {
  const { chainId } = useActiveWeb3React()
  const suggestStakeFarmiiModalOpen = useModalOpen(ApplicationModal.SUGGEST_STAKE_FARMII)
  const toggleSuggestStakeFarmiiModal = useSuggestStakeFarmiiModalToggle()
  const theme = useContext(ThemeContext)
  const latestAddTransaction = useLatestConfirmedAddLiquidityTransaction()
  const farms = useFarms()
  const farmiiFarm = Object.values(farms).find(
    (farm) =>
      latestAddTransaction?.summary?.includes(farm?.token0?.symbol) &&
      latestAddTransaction?.summary?.includes(farm?.token1?.symbol)
  )

  return (
    <Modal
      isOpen={suggestStakeFarmiiModalOpen}
      onDismiss={toggleSuggestStakeFarmiiModal}
      minHeight={false}
      maxHeight={90}
    >
      <Wrapper>
        <CloseIcon onClick={toggleSuggestStakeFarmiiModal}>
          <CloseColor />
        </CloseIcon>
        <CheckCircle />
        <GapColumn>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Success</Trans>
          </Text>
          <Text fontWeight={600} fontSize={14}>
            {latestAddTransaction?.summary}
          </Text>
          {chainId && latestAddTransaction?.hash && (
            <ExternalLink href={getExplorerLink(chainId, latestAddTransaction?.hash, ExplorerDataType.TRANSACTION)}>
              <Trans>View on Explorer</Trans>
            </ExternalLink>
          )}
          {farmiiFarm ? (
            <Text textAlign="center" fontWeight={500} fontSize={14} color={theme.text4} opacity={0.6}>
              <Trans>
                Next, stake your tokens on{' '}
                <ExternalLink href={FARMII_URL}>
                  <Trans>Farmii</Trans>
                </ExternalLink>{' '}
                to start earning additional rewards
              </Trans>
            </Text>
          ) : null}
        </GapColumn>
        <GapRow>
          {farmiiFarm ? (
            <ButtonPrimary onClick={() => window.open(FARMII_URL, '_blank')}>
              <Trans>Go to Farmii</Trans>
            </ButtonPrimary>
          ) : null}
          <ButtonSecondary
            style={{ height: '100%', borderColor: theme.primary1 }}
            onClick={() => toggleSuggestStakeFarmiiModal()}
          >
            <Trans>Close</Trans>
          </ButtonSecondary>
        </GapRow>
      </Wrapper>
    </Modal>
  )
}

import { useEffect } from 'react'
import useLazyFetch from 'hooks/useLazyFetch'
import { FX_API_BASE } from 'constants/general'
import { TopTokensTableData, MarketTableData } from 'components/Table/types'
import { LoaderWrapped } from 'theme/components'

type Routes =
  | 'tokens'
  | 'tokens/gainers'
  | 'tokens/losers'
  | 'tokens/new'
  | 'tokens/stats'
  | 'pools'
  | 'pools/new'
  | 'pools/gainers'
  | 'pools/losers'
  | 'wallets/assets'
  | 'wallets/pools'
  | 'wallets/farms'
  | 'tokens/general-stats'
  | 'pools/stats-local-volume'
  | 'pools/transactions'
  | 'markets'

type ApiParams = {
  route: Routes
  limit?: number
  rootData?: boolean
  service?: string
}

interface FetchInterface<T> {
  loader: JSX.Element | boolean
  abortController: { abort: () => void }
  data?: T[] | undefined
}

interface FetchInterfaceDetail<T> {
  loader: JSX.Element
  abortController: { abort: () => void }
  data?: T | undefined
  error?: any
}

interface IGraph {
  time: string
  volume: number
  fees: number
}

export interface IPoolGraph extends IGraph {
  liquidity: number
}

interface ITokenGraph extends IGraph {
  tvl: number
}
export interface ITokenDetail {
  address: string
  decimals: string
  priceHigh24hUSD: string
  priceLow24hUSD: string
  mcap: string
  price: string
  trading: string
  symbol: string
}

export interface IPoolDetail {
  address: string
  liquidity: string
  apy: string
  niiApy: string
  niifiApy: string
  totalApy: string
  timestamp: number
  token0: ITokenDetail
  token1: ITokenDetail
}

export interface IMarketDetail extends MarketTableData {
  utilizationRate: string
  depositAPR: string
  stableBorrowAPR: string
  variableBorrowAPR: string
  ltv: string
  liquidationThreshold: string
  liquidationPenalty: string
  stableBorrowOverTotal: string
  variableBorrowOverTotal: string
}

export type PoolTypes = 'gainers' | 'losers' | 'new'

export default function useApi({ route, limit, rootData, service }: ApiParams): any {
  // TODO: re-check limit usage with real API
  // const apiUrl = `${FX_API_BASE}${route}` + (limit ? '?' + new URLSearchParams({ _limit: limit.toString() }) : '')
  const abortController = new AbortController()
  const signal = abortController.signal
  const apiUrl = (service || FX_API_BASE) + route
  const [fetch, { data, error, loading }] = useLazyFetch<any[]>(apiUrl, rootData)

  useEffect(() => {
    route && fetch({ signal })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, fetch, limit])

  // TODO: add error handling
  useEffect(() => {
    if (!error) {
      return
    }
    console.log(error)
  }, [error])

  if (loading) {
    return { loader: LoaderWrapped(), data: [] }
  }

  return { loader: false, data, abortController, error }
}

export function useApiTokens(): FetchInterface<TopTokensTableData> {
  return useApi({ route: 'tokens?whitelist=true' as Routes })
}

export function useApiLendingTransactions(address: string | null | undefined): FetchInterface<any> {
  return useApi({ route: `wallets/${address}/lending-transactions` as unknown as Routes })
}

export function useApiPoolStatsGeneral(period: string): FetchInterface<IPoolGraph> {
  return useApi({ route: `pools/stats/${period}` as unknown as Routes })
}

export function useApiMarkets(): FetchInterface<MarketTableData> {
  return useApi({ route: 'markets' })
}

export function useApiMarket(address: string | null | undefined): FetchInterfaceDetail<IMarketDetail> {
  return useApi({ route: `markets/${address}` as unknown as Routes })
}

export function useApiMarketStats(address: string | null | undefined): FetchInterface<ITokenGraph> {
  return useApi({ route: `markets/stats/${address}` as unknown as Routes })
}

import React from 'react'

type SvgProps = React.SVGProps<SVGSVGElement>

export function ArrowDownGroupSvg({ width = 145, height = 112, ...rest }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 145 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.3429 23.3101L37.3429 91.5927L8.04663 63.8384L0.636719 71.66L42.73 111.538L84.8232 71.66L77.4133 63.8384L48.1171 91.5927L48.1171 23.3101H37.3429Z"
        fill="url(#paint0_linear_4080_3651)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.364 0.277344L100.364 40.522L83.3126 24.1641L78.9997 28.774L103.5 52.2774L128 28.774L123.687 24.1641L106.635 40.522V0.277345L100.364 0.277344Z"
        fill="url(#paint0_linear_4080_3651)"
        filter="url(#f1_4080_3651)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.62 75.9879L125.62 103.859L113.662 92.5305L110.637 95.723L127.818 112L145 95.723L141.975 92.5305L130.017 103.859V75.9879H125.62Z"
        fill="url(#paint0_linear_4080_3651)"
        filter="url(#f2_4080_3651)"
      />
      <defs>
        <filter id="f1_4080_3651" x="0" y="0" xmlns="http://www.w3.org/2000/svg">
          <feGaussianBlur stdDeviation="2" />
        </filter>
        <filter id="f2_4080_3651" x="0" y="0" xmlns="http://www.w3.org/2000/svg">
          <feGaussianBlur stdDeviation="3" />
        </filter>
        <linearGradient id="paint0_linear_4080_3651" x1="0.5" y1="0" x2="0.5" y2="1">
          <stop stopColor="rgb(242, 91, 67, 0.8)" />
          <stop offset="1" stopColor="rgb(242, 91, 67, 0.2)" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function ArrowUpGroupSvg({ width = 145, height = 112, ...rest }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 145 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.017 112V84.1289L141.975 95.4574L145 92.2649L127.819 75.9878L110.637 92.2649L113.662 95.4574L125.62 84.1289L125.62 112H130.017Z"
        fill="url(#paint0_linear_4080_3649)"
        filter="url(#f2_4080_3649)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.636 52.2773V12.0326L123.687 28.3906L128 23.7807L103.5 0.277344L79 23.7807L83.3129 28.3906L100.364 12.0326V52.2773H106.636Z"
        fill="url(#paint0_linear_4080_3649)"
        filter="url(#f1_4080_3649)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1176 111.538L48.1176 43.2551L77.4138 71.0094L84.8237 63.1879L42.7305 23.3101L0.637207 63.1879L8.04712 71.0094L37.3434 43.2551L37.3433 111.538H48.1176Z"
        fill="url(#paint0_linear_4080_3649)"
      />
      <defs>
        <filter id="f1_4080_3649" x="0" y="0" xmlns="http://www.w3.org/2000/svg">
          <feGaussianBlur stdDeviation="2" />
        </filter>
        <filter id="f2_4080_3649" x="0" y="0" xmlns="http://www.w3.org/2000/svg">
          <feGaussianBlur stdDeviation="3" />
        </filter>
        <linearGradient id="paint0_linear_4080_3649" x1="0.5" y1="0" x2="0.5" y2="1">
          <stop stopColor="rgb(70, 224, 100, 0.8)" />
          <stop offset="1" stopColor="rgb(70, 224, 100, 0.2)" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function BoxGroupSvg({ width = 145, height = 112, ...rest }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 136 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6301 33L83 56.6202V104.38L41.6301 128L0 104.388V56.6124L41.6301 33ZM8.3 66.1609L17.7174 71.5986L37.4733 82.8805V116.157L8.3 99.6104V66.1609ZM45.7733 116.134L74.7 99.6182V66.1484L65.6567 71.3518L65.647 71.3574L45.7733 82.8641V116.134ZM41.6089 75.7414L55.2719 67.8307L41.6493 60.095L28.063 68.0058L41.6089 75.7414ZM19.7962 63.2717L12.4551 59.0328L37.4733 44.8426V52.979L19.7962 63.2717ZM45.7733 52.9492L63.5512 63.0445L70.5544 59.0148L45.7733 44.866V52.9492Z"
        fill="url(#paint0_linear_4080_3653)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.574 0L125 13.4262V40.5738L101.574 54L78 40.5782V13.4218L101.574 0ZM82.7 18.8494L88.0327 21.9403L99.2198 28.3531V47.2684L82.7 37.8627V18.8494ZM103.92 47.2551L120.3 37.8672V18.8422L115.179 21.8L115.174 21.8032L103.92 28.3438V47.2551ZM101.562 24.2951L109.299 19.7985L101.585 15.4014L93.8911 19.898L101.562 24.2951ZM89.2099 17.207L85.0529 14.7976L99.2198 6.73161V11.3565L89.2099 17.207ZM103.92 11.3395L113.987 17.0779L117.952 14.7874L103.92 6.74488V11.3395Z"
        fill="url(#paint0_linear_4080_3653)"
        filter="url(#f1_4080_3653)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.555 88L136 97.9454V118.055L118.555 128L101 118.058V97.9421L118.555 88ZM104.5 101.962L108.471 104.252L116.802 109.002V123.014L104.5 116.046V101.962ZM120.302 123.004L132.5 116.05V101.957L128.687 104.148L128.682 104.15L120.302 108.995V123.004ZM118.546 105.996L124.307 102.666L118.563 99.4084L112.834 102.739L118.546 105.996ZM109.348 100.746L106.252 98.9612L116.802 92.9864V96.4122L109.348 100.746ZM120.302 96.3997L127.799 100.65L130.752 98.9536L120.302 92.9962V96.3997Z"
        fill="url(#paint0_linear_4080_3653)"
        filter="url(#f2_4080_3653)"
      />
      <defs>
        <filter id="f1_4080_3653" x="0" y="0" xmlns="http://www.w3.org/2000/svg">
          <feGaussianBlur stdDeviation="2" />
        </filter>
        <filter id="f2_4080_3653" x="0" y="0" xmlns="http://www.w3.org/2000/svg">
          <feGaussianBlur stdDeviation="3" />
        </filter>
        <linearGradient id="paint0_linear_4080_3653" x1="0.5" y1="0" x2="0.5" y2="1">
          <stop stopColor="rgb(144, 134, 250, 0.8)" />
          <stop offset="1" stopColor="rgb(144, 134, 250, 0.2)" />
        </linearGradient>
      </defs>
    </svg>
  )
}

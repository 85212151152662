import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import { t, Trans } from '@lingui/macro'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { DefaultTheme } from 'styled-components'
import CurrencyAvatar from 'components/CurrencyAvatar'
import { formatPercentValues } from 'utils'
import { RowWrapper, ColumnWrapper, BaseCurrencyView, TYPE } from 'theme'
import SearchableTable from './SearchableTable'
import { PoolTableData } from './types'
import { ButtonOutlined } from 'components/Button'
import { History, LocationState } from 'history'
import InfoHelper from 'components/InfoHelper'
import { AutoRow } from 'components/Row'
import { useAllPools } from 'hooks/useGraph'
import { OrderDir } from 'constants/queries'
import FarmiiIcon from '../../assets/svg/farmii-icon.svg'
import { ethers } from 'ethers'
import Tooltip from 'components/Tooltip'
import { useFarms } from 'state/pools/hooks'
import { FarmPoolsForPair } from 'types/farmii'

export const TooltipRow = styled(AutoRow)`
  line-height: 1.75rem;
  color: ${({ theme }) => theme.text1};
  gap: 4px;
`

const LogoWrapper = styled.div`
  :hover,
  :focus {
    opacity: 0.8;
  }
`

const TooltipWrapper = styled.div`
  color: ${({ theme }) => theme.text1};
`

export const FarmiiHelper = ({ poolAddress }: { poolAddress: string }) => {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip text={<TooltipWrapper>Visit Farmii to stake liquidity and earn rewards.</TooltipWrapper>} show={show}>
        <LogoWrapper
          role="button"
          style={{
            backgroundImage: `url(${FarmiiIcon})`,
            height: '1.3rem',
            width: '1.3rem',
            backgroundPosition: 'top',
            backgroundSize: 'contain',
            marginBottom: '1px',
            cursor: 'pointer',
          }}
          onClick={(event) => {
            window.open(`${window.__RUNTIME_CONFIG__.FARMII_URL}/farm?pool=${poolAddress}`, '_blank')
            event.stopPropagation()
          }}
          onMouseEnter={open}
          onMouseLeave={close}
        />
      </Tooltip>
    </span>
  )
}

const CustomTableRow = (
  row: PoolTableData,
  index: number,
  history: History<LocationState>,
  theme: DefaultTheme,
  farms: {
    [address: string]: FarmPoolsForPair
  }
) => {
  const StyledTableRow = styled(TableRow)`
    td:first-child {
      padding-left: 2rem;
    }
    td:last-child {
      padding-right: 2rem;
    }
  `
  const rowCellStyles = { color: theme.black, borderBottom: `1px solid ${theme.bg3}`, cursor: 'pointer' }

  const handleCellOnClick = (id: string) => {
    history.push(`/pool/${id}`)
  }

  const liquidity = parseFloat(row.liquidity)

  const farm = farms[ethers.utils.getAddress(row.address)] //.find((farm) => ethers.utils.getAddress(farm.pairAddress) === ethers.utils.getAddress(row.address))

  const apy = Number(row.totalApy || 0) + Number(farm?.farmApy || 0)

  return (
    <StyledTableRow
      hover
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      key={index}
      selected={false}
      onClick={() => handleCellOnClick(row.address)}
    >
      <TableCell style={rowCellStyles} align="left">
        <RowWrapper>
          <div style={{ position: 'relative' }}>
            <CurrencyAvatar
              symbol={row.token0.symbol}
              address={row.token0.address}
              iconProps={{ width: '32', height: '32' }}
              containerStyle={{ zIndex: 1 }}
              hideSymbol={true}
            />
            <CurrencyAvatar
              symbol={row.token1.symbol}
              address={row.token1.address}
              iconProps={{ width: '32', height: '32' }}
              containerStyle={{ left: '18px', position: 'absolute', marginTop: '-34px' }}
              hideSymbol={true}
            />
          </div>
          <RowWrapper style={{ marginLeft: '2.6rem', alignItems: 'center' }}>
            <TYPE.body fontWeight={500} style={{ display: 'flex' }}>
              {row.token0.symbol} / {row.token1.symbol}
            </TYPE.body>
          </RowWrapper>
        </RowWrapper>
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="symbol" value={liquidity} />
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <ColumnWrapper>
          <AutoRow style={{ gap: '4px' }}>
            <div>{formatPercentValues(apy)}</div>
            {farm ? <FarmiiHelper poolAddress={farm.pools?.[0]?.address} /> : null}
            {(!!row.apy || !!farm?.farmApy) && (
              <InfoHelper
                text={
                  <>
                    {!!row.apy && (
                      <TooltipRow>
                        <Trans>Trading APY</Trans>:
                        <div style={{ flexGrow: 1 }} />
                        <>{formatPercentValues(row.apy)}</>
                      </TooltipRow>
                    )}
                    {!!farm?.farmApy && (
                      <TooltipRow>
                        <img src={FarmiiIcon} style={{ height: '1.3rem', width: '1.3rem', marginBottom: '2px' }} />
                        <Trans>Farmii Liquidity Mining APY</Trans>:
                        <div style={{ flexGrow: 1 }} />
                        <>{formatPercentValues(farm?.farmApy || '-')}</>
                      </TooltipRow>
                    )}
                  </>
                }
                size={320}
              />
            )}
          </AutoRow>
        </ColumnWrapper>
      </TableCell>
      <TableCell style={rowCellStyles} align="center">
        <ButtonOutlined style={{ fontSize: '14px' }} padding={'10px 14px'}>
          <Trans>Add Liquidity</Trans>
        </ButtonOutlined>
      </TableCell>
    </StyledTableRow>
  )
}

export default function PoolsTable() {
  const history = useHistory()
  const theme = useTheme()
  const { state } = useLocation<any>() // FIXME: any
  const [fetchParams, setFetchParams] = React.useState<any>({
    orderBy: 'reserveUSD',
    orderDir: OrderDir.DESC,
    offset: 0,
    limit: 11,
  })
  const farms = useFarms()

  const [searchArg, setSearchArg] = useState<string>('')

  const [pools, setPools] = useState<PoolTableData[]>()

  const { data, fetching } = useAllPools({ ...fetchParams, searchArg })

  useEffect(() => {
    if (fetching || !data || data.length === 0) return
    setPools(data)
  }, [fetching, data])

  useEffect(() => {
    setFetchParams({
      orderBy: 'reserveUSD',
      orderDir: state?.type === 'looser' ? OrderDir.ASC : OrderDir.DESC,
      offset: 0,
      limit: 11,
    })
  }, [state?.type])

  return (
    <>
      {pools && (
        <SearchableTable
          key={state?.type}
          fetching={fetching}
          data={pools}
          searchLabel={t`Filter by token, pair, address ...`}
          debouncedSearchChange={(value: string) => {
            setSearchArg(value)
          }}
          headCells={[
            { id: 'symbolId', numeric: false, disablePadding: false, label: t`Available Pools` },
            { id: 'reserveUSD', numeric: true, disablePadding: false, label: t`TVL` },
            { id: 'totalApy', numeric: false, disablePadding: false, label: t`APY` },
            { id: '', numeric: false, disablePadding: false, label: '' },
          ]}
          row={(row: any, index: number) => CustomTableRow(row, index, history, theme, farms)}
          defaultOrder={fetchParams.orderDir}
          defaultOrderBy={fetchParams.orderBy}
          setFetchParams={setFetchParams}
        />
      )}
    </>
  )
}

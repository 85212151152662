import { useState, useEffect } from 'react'
import { Token, CurrencyAmount, Currency } from '@niifi/godzilla2-sdk'
import { useTokenContract } from './useContract'
import { useBlockNumber } from '../state/application/hooks'
import { Contract } from 'ethers'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Currency): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.isToken ? token.address : undefined, false)
  const [totalSupply, setTotalSupply] = useState<string | undefined>()

  const latestBlockNumber = useBlockNumber()

  useEffect(() => {
    if (!latestBlockNumber || !contract) {
      return
    }

    async function setTokenSupply(tokenContract: Contract) {
      try {
        const supply = await tokenContract.totalSupply()
        setTotalSupply(supply.toString())
      } catch {}
    }

    setTokenSupply(contract)
  }, [latestBlockNumber, contract])

  return token?.isToken && totalSupply ? CurrencyAmount.fromRawAmount(token, totalSupply) : undefined
}

import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import ToggleDrawer from 'components/Header/ToggleDrawer'
import AppBar from 'components/AppBar'
import CurrencyDropdown from 'components/Dropdowns/CurrencyDropdown'
import AppBody from '../AppBody'
import { AutoColumn, FlexColumn } from 'components/Column'
import { ResponsiveRow } from 'components/Row'
import { BodyScroller, CurrencySelectWrapper, TYPE, BaseCurrencyView } from 'theme'
import { useAllTokenBalances } from 'state/wallet/hooks'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { useApiTokens } from 'hooks/useApi'
import OverviewChart from 'components/LineChart/overview'
import BarChart from 'components/BarChart/overview'
import { DefaultCard } from 'components/Card'
import { Wrapper } from 'components/swap/styleds'
import OverviewTable from 'components/Table/overview'
import { AssetsTableData } from 'components/Table/types'
import { LoaderWrapped } from 'theme/components'
import { BodyPanel } from 'pages/styled'
import { useDailyStats } from 'hooks/useGraph'

const StyledAppBar = styled(AppBar)`
  padding: 0px 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0px 0.625rem;
  `}
`

type WalletProps =
  | {
      balanceUSD: number
      data: AssetsTableData[]
      loader?: JSX.Element | boolean
    }
  | undefined

export function useWalletData(): WalletProps {
  const { data, abortController } = useApiTokens()
  const balances = useAllTokenBalances()

  useEffect(() => {
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo((): WalletProps => {
    if (!balances || !data || !data.length) return { balanceUSD: 0, data: [], loader: <LoaderWrapped /> }

    const balancesFormated: AssetsTableData[] = []
    let totalWalletBalance = 0

    Object.keys(balances).map(async (address) => {
      const symbol = balances[address]?.currency.symbol || ''
      const balance = parseFloat(formatCurrencyAmount(balances[address], 8))
      const price = parseFloat(data.find((x) => x.address === address)?.priceUSD || '')
      const total = balance * price
      totalWalletBalance += total
      balancesFormated.push({
        address,
        symbol,
        balance,
        price,
        total,
      })
    })

    balancesFormated.sort((a, b) => (a.symbol > b.symbol ? 1 : -1))

    return { balanceUSD: totalWalletBalance, data: balancesFormated }
  }, [balances, data])
}

export default function Dashboard() {
  const { state } = useLocation<any>()
  const { data: statsData, fetching } = useDailyStats({ limit: 1 })

  useEffect(() => {
    if (!state?.type) {
      return
    }

    const scrollTo = setTimeout(
      () => document.querySelector('#top-tokens-table')?.scrollIntoView({ behavior: 'smooth' }),
      800
    )
    return () => {
      clearTimeout(scrollTo)
    }
  }, [state])

  return (
    <>
      <StyledAppBar>
        <ToggleDrawer />
        <div />
        <CurrencySelectWrapper>
          <CurrencyDropdown />
        </CurrencySelectWrapper>
      </StyledAppBar>
      <BodyScroller>
        <BodyPanel>
          <AutoColumn gap="lg">
            <ResponsiveRow gap="2rem">
              <AppBody size="md">
                <Wrapper>
                  <OverviewChart />
                </Wrapper>
              </AppBody>
              <AppBody size="md">
                <Wrapper>
                  <BarChart />
                </Wrapper>
              </AppBody>
            </ResponsiveRow>
            <ResponsiveRow gap="2rem">
              {fetching ||
                (statsData && statsData.length > 0 && (
                  <>
                    <DefaultCard padding="1.5rem" minHeight="100px">
                      <TYPE.subHeader fontSize="16px">
                        <Trans>Volume 24H</Trans>
                      </TYPE.subHeader>
                      <FlexColumn style={{ padding: '5px 0' }}>
                        <TYPE.mediumHeader color="text1">
                          <BaseCurrencyView type="symbol" value={statsData[0].volume} />
                        </TYPE.mediumHeader>
                        {/* TODO: add percentage when it'll be available in API */}
                        {/* <Percent value={7.258268337244848} fontWeight={400} /> */}
                      </FlexColumn>
                    </DefaultCard>
                    <DefaultCard padding="1.5rem" minHeight="100px">
                      <TYPE.subHeader fontSize="16px">
                        <Trans>Fees 24H</Trans>
                      </TYPE.subHeader>
                      <FlexColumn style={{ padding: '5px 0' }}>
                        <TYPE.mediumHeader color="text1">
                          <BaseCurrencyView type="symbol" value={statsData[0].fees} />
                        </TYPE.mediumHeader>
                        {/* <Percent value={7.858268337244848} fontWeight={400} /> */}
                      </FlexColumn>
                    </DefaultCard>
                    <DefaultCard padding="1.5rem" minHeight="100px">
                      <TYPE.subHeader fontSize="16px">
                        <Trans>TVL</Trans>
                      </TYPE.subHeader>
                      <FlexColumn style={{ padding: '5px 0' }}>
                        <TYPE.mediumHeader color="text1">
                          <BaseCurrencyView type="symbol" value={statsData[0].tvl} />
                        </TYPE.mediumHeader>
                      </FlexColumn>
                    </DefaultCard>
                  </>
                ))}
            </ResponsiveRow>
            <ResponsiveRow id="top-tokens-table">
              <AppBody size="lg">
                <OverviewTable />
              </AppBody>
            </ResponsiveRow>
          </AutoColumn>
        </BodyPanel>
      </BodyScroller>
    </>
  )
}

import { createAction } from '@reduxjs/toolkit'
import { SupportedLocale } from 'constants/locales'
import { SupportedBaseCurrencies } from 'constants/tokens'
export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}

export enum UserWalletTypes {
  'WATCHED' = 'WATCHED',
  'CONNECTED' = 'CONNECTED',
}
export interface UserWallets {
  name: string
  type: UserWalletTypes
  timestamp: number
}
export type BuyTokenStates = 'buy' | 'process' | 'deposit'
export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')
export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode')
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>('user/updateUserExpertMode')
export const updateUserLocale = createAction<{ userLocale: SupportedLocale }>('user/updateUserLocale')
export const updateUserSingleHopOnly = createAction<{ userSingleHopOnly: boolean }>('user/updateUserSingleHopOnly')
export const updateHideClosedPositions = createAction<{ userHideClosedPositions: boolean }>('user/hideClosedPositions')
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number | 'auto' }>(
  'user/updateUserSlippageTolerance'
)
export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline')
export const addSerializedToken = createAction<{ serializedToken: SerializedToken }>('user/addSerializedToken')
export const removeSerializedToken = createAction<{ chainId: number; address: string }>('user/removeSerializedToken')
export const addSerializedPair = createAction<{ serializedPair: SerializedPair }>('user/addSerializedPair')
export const removeSerializedPair =
  createAction<{ chainId: number; tokenAAddress: string; tokenBAddress: string }>('user/removeSerializedPair')
export const toggleURLWarning = createAction<void>('app/toggleURLWarning')
export const saveNewWallet =
  createAction<{ address: string; name?: string; type: UserWalletTypes }>('user/saveNewWallet')
export const updateWallet = createAction<{ address: string; name: string }>('user/updateWallet')
export const removeWallet = createAction<{ address: string }>('user/removeWallet')
export const setRecentConnectedWallet = createAction<{ address: string }>('user/setRecentConnectWallet')
export const setBaseCurrency = createAction<SupportedBaseCurrencies>('user/setBaseCurrency')
export const setEthereumToBaseCurrencyRates = createAction<{ [key: string]: number }>(
  'user/setEthereumToBaseCurrencyRates'
)

export const setBuyTokenState = createAction<BuyTokenStates>('user/buyTokenState')

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const unixToDate = (timestamp: number) => {
  return dayjs.unix(timestamp).format('YYYY-MM-DD')
}

export const dateToChartDate = (date: string) => {
  return dayjs(date).format('MMM D, YYYY')
}

export const getTodayTimestamp = () => {
  return dayjs().utc().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).unix()
}

export const getStartRangeTimestamp = (days: number) => {
  return dayjs().utc().subtract(days, 'day').unix()
}

import { gql } from 'urql'
import { PAIR_PAIR_DATA_FIELDS } from './fragments'

export const GetNewPools = gql`
  ${PAIR_PAIR_DATA_FIELDS}
  query ($limit: Int, $offset: Int) {
    pairs(first: $limit, skip: $offset, orderBy: createdAtTimestamp, orderDirection: desc) {
      ...PairData
    }
  }
`

export const GetAllPools = gql`
  ${PAIR_PAIR_DATA_FIELDS}
  query ($limit: Int, $offset: Int, $orderBy: String, $orderDir: String) {
    pairs(first: $limit, skip: $offset, orderBy: $orderBy, orderDirection: $orderDir) {
      ...PairData
    }
  }
`

export const GetPool = gql`
  query ($id: String) {
    pair(id: $id) {
      symbolId
      address: id
      liquidity: reserveUSD
      token0 {
        address: id
        decimals
        symbol
        trading: tradeVolumeUSD
        mcap: marketCapUSD
        pairDayDataBase(where: { pairAddress: $id }, first: 1, orderBy: date, orderDirection: desc) {
          token0PriceHigh24h
          token0PriceLow24h
        }
      }
      token1 {
        address: id
        decimals
        symbol
        trading: tradeVolumeUSD
        mcap: marketCapUSD
        pairDayDataQuote(where: { pairAddress: $id }, first: 1, orderBy: date, orderDirection: desc) {
          token1PriceHigh24h
          token1PriceLow24h
        }
      }
      token0Price: token1Price
      token1Price: token0Price
      apy
      niiApy
      niifiApy
      totalApy
    }
  }
`

export const GetPoolChartData = gql`
  query ($id: String, $ts: Int) {
    pairDayDatas(where: { pairAddress: $id, date_gte: $ts }, orderBy: date, orderDirection: asc) {
      date
      tvl: reserveUSD
      volume: dailyVolumeUSD
      fees: dailyFeesUSD
    }
  }
`

// TODO: Likely performance issues
export const GetPoolByParams = gql`
  ${PAIR_PAIR_DATA_FIELDS}
  query ($searchArg: String, $limit: Int, $offset: Int, $orderBy: String, $orderDir: String) {
    pairs(
      where: {
        or: [
          { stringId_starts_with_nocase: $searchArg }
          { symbolId_contains_nocase: $searchArg }
          { stringToken0_starts_with_nocase: $searchArg }
          { stringToken1_starts_with_nocase: $searchArg }
        ]
      }
      first: $limit
      skip: $offset
      orderBy: $orderBy
      orderDirection: $orderDir
    ) {
      ...PairData
    }
  }
`

export const GetPoolsByAddress = gql`
  query ($pairsAddresses: [String]!) {
    pairs(where: { id_in: $pairsAddresses }) {
      address: id
      apy
      reserveUSD
      totalSupply
    }
  }
`

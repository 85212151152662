import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { orderBy as lodashOrderBy, get } from 'lodash'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import styled, { DefaultTheme } from 'styled-components'
import useTheme from 'hooks/useTheme'
import { ArrowDownIcon, ArrowUpIcon } from '../Icons'
import TableToolBar from './TableToolbar'
import { TableDataTypes, TransactionTypes } from './types'

export type Order = 'asc' | 'desc'

export interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

const StyledTableRow = styled(TableRow)`
  padding: 0.1rem;

  th:first-child {
    padding-left: 2rem;
  }
  th:last-child {
    padding-right: 2rem;
  }
`

interface RenderToolBarProps {
  onNext: (page: number) => void
  onBack: (page: number) => void
  currentPage: number
  totalPages: number
}
interface EnhancedTableProps {
  title?: any
  onClick?: (event: React.MouseEvent<unknown>, rowId: string) => unknown
  data: Array<TableDataTypes>
  hideHeader?: boolean
  headCells: HeadCell[]
  rowsPerPage?: number
  row: (
    row: any,
    index: number,
    theme: DefaultTheme,
    handleClick: (event: React.MouseEvent<unknown>, name: string) => void
  ) => unknown
  headCellsBefore?: (props: any) => unknown
  renderToolbar?: (props: RenderToolBarProps) => any
  defaultOrder?: Order
  defaultOrderBy?: string
  setFetchParams?: (params: any) => void
  fetching?: boolean
}
interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
  onTransactionTypeChange: (type: any) => void
  transactionType: string
  headCells: HeadCell[]
  renderCells?: (headCell: HeadCell) => JSX.Element
  cellsBefore?: (props: any) => unknown
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const theme = useTheme()
  const { order, orderBy, onRequestSort, headCells, cellsBefore } = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  const renderCells =
    props.renderCells ||
    ((headCell: HeadCell) => {
      return (
        <TableCell
          style={{ color: `${theme.text4}`, borderBottom: `1px solid ${theme.bg3}` }}
          key={headCell.id}
          align={headCell.align || 'left'}
          padding={headCell.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          {headCell.label === '#' ? (
            headCell.label
          ) : (
            <TableSortLabel
              active={orderBy === headCell.id}
              style={{ color: orderBy === headCell.id ? theme.primary1 : theme.text4 }}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={() =>
                order === 'desc' ? (
                  <ArrowDownIcon
                    style={{ marginLeft: '4px' }}
                    width="12px"
                    height="12px"
                    color={orderBy === headCell.id ? theme.primary1 : ''}
                  />
                ) : (
                  <ArrowUpIcon
                    style={{ marginLeft: '4px' }}
                    width="12px"
                    height="12px"
                    color={orderBy === headCell.id ? theme.primary1 : ''}
                  />
                )
              }
            >
              {headCell.label}
            </TableSortLabel>
          )}
        </TableCell>
      )
    })

  return (
    <TableHead>
      <StyledTableRow>
        {cellsBefore && cellsBefore(props)}
        {headCells.map(renderCells)}
      </StyledTableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiTableCell-head.MuiTableCell-alignLeft:first-child': {
        paddingLeft: '32px',
      },
    },
    paper: {
      width: '100%',
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
)

export default function EnhancedTable({ hideHeader = false, ...props }: EnhancedTableProps) {
  const { defaultOrder, defaultOrderBy, setFetchParams } = props
  const theme = useTheme()
  const classes = useStyles()
  const tableData: TableDataTypes[] = props.data || []
  const [transactionType, setTransactionType] = React.useState<TransactionTypes>('All')
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('priceUSD')
  const [page, setPage] = React.useState(0)
  const rowsPerPage = props.rowsPerPage || 10
  const [localSorting, setLocalSorting] = React.useState<boolean>(true)

  useEffect(() => {
    setPage(0)
  }, [transactionType])

  useEffect(() => {
    setFetchParams && setLocalSorting(false)
  }, [setFetchParams])

  useEffect(() => {
    if (defaultOrderBy !== undefined) {
      setOrderBy(defaultOrderBy)
      setOrder(defaultOrder || 'asc')
      setPage(0)
    }
  }, [defaultOrder, defaultOrderBy])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    if (!property) return
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setFetchParams &&
      setFetchParams({
        orderBy: property,
        orderDir: isAsc ? 'desc' : 'asc',
        offset: page * rowsPerPage,
        limit: rowsPerPage + 1,
      })
  }
  const handleClick = (event: React.MouseEvent<unknown>, rowId: string) => {
    props.onClick && props.onClick(event, rowId)
  }

  const handlePaginate = (newPage: number) => {
    setPage(newPage)
    setFetchParams &&
      setFetchParams({
        orderBy: orderBy,
        orderDir: order,
        offset: newPage * rowsPerPage,
        limit: rowsPerPage + 1,
      })
  }

  const hasMore = tableData.length > (localSorting ? rowsPerPage * (page + 1) : rowsPerPage)

  return (
    <div className={classes.root}>
      <TableToolBar
        currentPage={page}
        hasMore={!props.fetching && hasMore}
        title={props.title}
        onNext={(currentPage: number) => {
          if (!hasMore) return
          handlePaginate(currentPage + 1)
        }}
        onBack={(currentPage: number) => {
          if (currentPage === 0) return
          handlePaginate(currentPage - 1)
        }}
      />
      <TableContainer>
        <Table className={classes.table} size={'medium'} style={{ width: '100%', tableLayout: 'auto' }}>
          {!hideHeader && (
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onTransactionTypeChange={setTransactionType}
              transactionType={transactionType}
              rowCount={tableData.length}
              headCells={props.headCells}
              cellsBefore={props.headCellsBefore}
            />
          )}
          <TableBody>
            {tableData
              ? localSorting
                ? lodashOrderBy(
                    tableData,
                    function (o) {
                      const dataPoint = get(o, orderBy)
                      if (orderBy !== 'address') return dataPoint
                      return Number(dataPoint)
                    },
                    [order]
                  )
                    .filter((row) =>
                      transactionType === 'All' ? true : 'type' in row ? transactionType === row.type : true
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => props.row(row, index, theme, handleClick))
                : (tableData.length > rowsPerPage ? tableData.slice(0, 10) : tableData).map((row, index) =>
                    props.row(row, page * rowsPerPage + index, theme, handleClick)
                  )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

import { SECONDS_IN_YEAR } from 'constants/misc'
import { useFarmiiRewardPools } from 'hooks/useFarmiiGraph'
import { useEthPrice, usePoolsByAddresses, useTokensEthValue } from 'hooks/useGraph'
import { FarmPool, FarmPoolsForPair } from 'types/farmii'
import { formatUnits } from 'ethers/lib/utils'
import { useMemo } from 'react'

const useFarmsData = (): FarmPoolsForPair[] => {
  const { data: ethPrice } = useEthPrice()

  const { data: niifiFarmsFromSubgraph } = useFarmiiRewardPools()
  const { data: userNiifiFarmsPositions } = { data: [] as any[] }

  const niifiFarmsPairsAddresses = useMemo(
    () => niifiFarmsFromSubgraph.map((data) => data.pairAddress),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(niifiFarmsFromSubgraph)]
  )

  const { data: poolsData } = usePoolsByAddresses(niifiFarmsPairsAddresses)

  const poolsDataMap = useMemo(
    () => poolsData.reduce((acc, data) => ({ ...acc, [data.address]: data }), {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(poolsData)]
  )

  const rewardTokensAddresses = useMemo(
    () => {
      const addresses = niifiFarmsFromSubgraph.reduce<string[]>((acc, data) => {
        return data.pools.reduce((acc: string[], x: FarmPool) => [...acc, x.rewardToken.address], acc)
      }, [])
      return Array.from(new Set(addresses))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(niifiFarmsFromSubgraph)]
  )

  const { data: rewardTokensEthValues } = useTokensEthValue(rewardTokensAddresses)

  const rewardTokensEthValuesMap = useMemo(
    () =>
      rewardTokensEthValues.reduce<{ [key: string]: number }>(
        (acc, data) => ({ ...acc, [data.address]: Number(data.valueInEth) }),
        {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(rewardTokensEthValues)]
  )

  // array of pools addresses where user has stake
  const niffifarmPoolsAddressesWithStakes: string[] = useMemo(
    () =>
      userNiifiFarmsPositions.reduce<string[]>((acc, data) => {
        if (Number(data.amountStaked) > 0) {
          return [...acc, data.pool.address]
        }
        return acc
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userNiifiFarmsPositions)]
  )

  // checking first pool is enough to know if user has stake
  const niifiFarms = useMemo(
    () =>
      niifiFarmsFromSubgraph.map((x: FarmPoolsForPair) => {
        let farmApy = 0
        const pools = x.pools.map((y: FarmPool) => {
          const apy = calculateFarmPoolAPY(
            Number(formatUnits(y.notifiedReward, y.rewardToken.decimals)),
            rewardTokensEthValuesMap[y.rewardToken.address],
            ethPrice,
            Number(y.rewardDuration),
            Number(poolsDataMap[x.pairAddress]?.reserveUSD || 0),
            Number(formatUnits(y.totalLPStakedInWei, y.lpToken.decimals)),
            Number(poolsDataMap[x.pairAddress]?.totalSupply || 0)
          )
          farmApy += apy
          return {
            ...y,
            apy,
          }
        })
        return {
          ...x,
          farmApy,
          pools,
          userHasStake: niffifarmPoolsAddressesWithStakes.includes(x.pools[0].address),
        }
      }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      ethPrice,
      JSON.stringify(niffifarmPoolsAddressesWithStakes),
      JSON.stringify(niifiFarmsFromSubgraph),
      JSON.stringify(poolsDataMap),
      JSON.stringify(rewardTokensEthValuesMap),
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  )

  return niifiFarms
}

const calculateFarmPoolAPY = (
  notifiedReward: number,
  rewardTokenderivedEthValue: number,
  ethPriceUsdPrice: number,
  rewardDuration: number,
  poolReserveInUsd: number,
  totalStakedLP: number,
  LPTotalSupply: number
) => {
  const rewardTokenUsdPrice = rewardTokenderivedEthValue * ethPriceUsdPrice

  const numerator = ((notifiedReward * rewardTokenUsdPrice) / rewardDuration) * SECONDS_IN_YEAR

  const denominator = poolReserveInUsd * (totalStakedLP / LPTotalSupply)

  if (denominator === 0) return 0

  const apy = (numerator / denominator) * 100

  return apy
}

export default useFarmsData

import { gql } from 'urql'

export const PoolsToFarmiis = gql`
  query {
    poolsToFarmiis {
      pairAddress: id
      pairDexName: exchangeName
      token0 {
        address: id
        name
        symbol
        decimals
      }
      token1 {
        address: id
        name
        symbol
        decimals
      }
      pools: rewardPools(orderDirection: asc, orderBy: createdAt) {
        address: id
        totalLPStakedInWei: totalSupply
        notifiedReward
        rewardDuration: duration
        lpToken {
          address: id
          name
          symbol
          decimals
        }
        rewardToken {
          address: id
          name
          symbol
          decimals
        }
      }
    }
  }
`

export const positionInRewardPools = gql`
  query ($userAddress: String!) {
    positionInRewardPools(where: { user: $userAddress }) {
      amountStaked: fTokenBalance
      pool: rewardPool {
        address: id
      }
      user: user {
        address: id
      }
    }
  }
`

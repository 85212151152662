import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory, Redirect } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import { Trans, t } from '@lingui/macro'
import { RouteComponentProps } from 'react-router-dom'
import CreatePoolButton from 'components/pools/CreatePoolButton'
import { CustomAppBar } from 'pages/styled'
import AppBody, { BodyWrapper } from '../AppBody'
import { AutoColumn } from 'components/Column'
import { ResponsiveRow } from 'components/Row'
import { BodyPanel } from '../styled'
import PoolDetailChart from 'components/LineChart/PoolDetail'
import { BodyScroller, TYPE, BarTitle, CurrencySelectWrapper, RowWrapper } from 'theme'
import PoolInvest from 'components/PoolInvest'
import PoolWithdraw from 'components/PoolWithdraw'
import CurrencyDropdown from 'components/Dropdowns/CurrencyDropdown'
import CurrencyAvatar from 'components/CurrencyAvatar'
import TokenDetails from './TokenDetails'
import Tab from '../../components/tab/Tab'
import Tabs from '../../components/tab/Tabs'
import TabPanel from '../../components/tab/TabPanel'
import InfoHelper from 'components/InfoHelper'
import { FarmiiHelper, TooltipRow } from 'components/Table/PoolsTable'
import useTheme from '../../hooks/useTheme'
import { useCurrency } from 'hooks/Tokens'
import { useToken, usePool } from 'hooks/useGraph'
import { BaseCurrencyView } from 'theme'
import { formatPercentValues, shortenDecimalValues } from 'utils'
import { useActiveWeb3React } from 'hooks/web3'
import { WETH9_EXTENDED } from 'constants/tokens'
import { useFarms } from 'state/pools/hooks'
import { PoolData } from 'state/pools/reducer'
import FarmiiIcon from '../../assets/svg/farmii-icon.svg'
import { ITokenDetail } from 'hooks/useApi'

const TokenStatsWrapper = styled(BodyWrapper)`
  flex: 2;
  padding: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 1rem;
  `}
`
const RowColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 1rem;
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 1rem;
  `}
`

const PoolCardRowColumn = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 1rem;
  `}
`
const PoolCardItem = styled(AutoColumn)`
  :nth-child(1) {
    border-right: 1px solid ${({ theme }) => theme.bg3};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    :nth-child(1) {
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
  `}
`

const rootStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  fontWeight: 500,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
}

const selectedStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  fontWeight: 500,
  borderRadius: '0px',
}

const wrapperStyle = {
  root: rootStyle,
  hover: selectedStyle,
  selected: selectedStyle,
}

export default function PoolDetails({
  match: {
    params: { address },
  },
}: RouteComponentProps<{ address: string }>) {
  const { chainId } = useActiveWeb3React()
  const [activeTab, setActiveTab] = useState<number>(0)
  const [token0, setToken0] = useState('')
  const [token1, setToken1] = useState('')
  const [useEth, setUseEth] = useState(false)
  const history = useHistory()
  const theme = useTheme()
  wrapperStyle.root.borderBottomColor = theme.bg3
  wrapperStyle.hover.borderBottomColor = theme.primary1
  wrapperStyle.selected.borderBottomColor = theme.primary1

  const { data, fetching: poolFetching, error } = usePool({ address: address || '' })

  const poolData = data as PoolData & {
    apy: number
  }

  useEffect(() => {
    if (!poolData) return
    setToken0(poolData?.token0?.address)
    setToken1(poolData?.token1?.address)
  }, [poolData])

  const farms = useFarms()

  const farm = farms?.[poolData?.address]

  const apy = useMemo(() => {
    return Number(poolData?.apy || 0) + Number(farm?.farmApy || 0)
  }, [farm, poolData])

  // TODO: move all token prices to redux after fetching tokens from BE
  const { data: token0Data, fetching: token0Fetching } = useToken({ address: token0 || '' })
  const { data: token1Data, fetching: token1Fetching } = useToken({ address: token1 || '' })

  const _currency0 = useCurrency(token0, poolData?.token0)
  const _currency1 = useCurrency(token1, poolData?.token1)

  const wrappedNativeCurrency = chainId ? WETH9_EXTENDED[chainId] : undefined

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      wrappedNativeCurrency &&
      ((_currency0 && _currency0.equals(wrappedNativeCurrency)) ||
        (_currency1 && _currency1.equals(wrappedNativeCurrency)))
  )

  const WETHIndex =
    chainId && wrappedNativeCurrency
      ? _currency0 && _currency0.equals(wrappedNativeCurrency)
        ? 0
        : _currency1 && _currency1.equals(wrappedNativeCurrency)
        ? 1
        : undefined
      : undefined

  const currency0 = useCurrency(WETHIndex === 0 && useEth ? 'ETH' : token0, poolData?.token0)
  const currency1 = useCurrency(WETHIndex === 1 && useEth ? 'ETH' : token1, poolData?.token1)

  const liquidity = parseFloat(String(poolData?.liquidity) || '')

  const TabChangeHandler: any = (e: any, newValue: any) => setActiveTab(newValue)

  if (error) {
    return <Redirect to="/pools" />
  }

  return (
    <>
      {poolFetching ||
        token0Fetching ||
        token1Fetching ||
        (poolData && poolData.token0 && poolData.token1 && (
          <>
            <CustomAppBar>
              <BarTitle>
                <ArrowLeft style={{ cursor: 'pointer' }} onClick={history.goBack} />
                {poolData.token0.symbol} / {poolData.token1.symbol} {t`Pool`}
              </BarTitle>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CreatePoolButton />
                <CurrencySelectWrapper>
                  <CurrencyDropdown />
                </CurrencySelectWrapper>
              </div>
            </CustomAppBar>
            <BodyScroller>
              <BodyPanel>
                <Wrapper>
                  <ResponsiveRow>
                    <AppBody size="md" padding={'1rem'} style={{ textAlign: 'center' }}>
                      <TYPE.body fontSize={22}>
                        <BaseCurrencyView type="symbol" value={liquidity} /> <Trans>TVL</Trans>
                      </TYPE.body>
                    </AppBody>
                    <AppBody size="md" padding={'1rem'} style={{ textAlign: 'center' }}>
                      <RowWrapper style={{ justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                        <TYPE.body fontSize={22}>
                          {Number(apy) && Number(apy) < 0.01 ? '< 0.01' : shortenDecimalValues(Number(apy), '0,0.00')}%{' '}
                          <Trans>APY</Trans>
                        </TYPE.body>
                        {farm && <FarmiiHelper poolAddress={farm.pools?.[0]?.address} />}
                        {(!!poolData?.apy || !!farm?.farmApy) && (
                          <InfoHelper
                            text={
                              <>
                                {!!poolData.apy && (
                                  <TooltipRow>
                                    <Trans>Trading APY</Trans>:
                                    <div style={{ flexGrow: 1 }} />
                                    <>{formatPercentValues(poolData.apy)}</>
                                  </TooltipRow>
                                )}
                                {!!farm?.farmApy && (
                                  <TooltipRow>
                                    <img src={FarmiiIcon} style={{ height: '1.3rem', width: '1.3rem' }} />
                                    <Trans>Farmii Liquidity Mining APY</Trans>:
                                    <div style={{ flexGrow: 1 }} />
                                    <>{formatPercentValues(farm?.farmApy || '-')}</>
                                  </TooltipRow>
                                )}
                              </>
                            }
                            size={320}
                          />
                        )}
                      </RowWrapper>
                    </AppBody>
                  </ResponsiveRow>
                  <ResponsiveRow>
                    <AppBody size="md" style={{ minHeight: '440px' }}>
                      <Tabs
                        style={{ padding: '1rem 2rem', paddingBottom: '0' }}
                        value={activeTab}
                        onChange={TabChangeHandler}
                      >
                        <Tab
                          key={`tab-0`}
                          label={t`Liquidity`}
                          style={{
                            backgroundColor: 'transparent',
                            minWidth: '50%',
                            paddingRight: '0px',
                            marginRight: '0px',
                          }}
                          wrapperStyles={wrapperStyle}
                        />
                        <Tab
                          key={`tab-1`}
                          label={t`Withdraw`}
                          style={{
                            backgroundColor: 'transparent',
                            minWidth: '50%',
                            paddingLeft: '0px',
                            marginLeft: '0px',
                          }}
                          wrapperStyles={wrapperStyle}
                        />
                      </Tabs>
                      <TabPanel key={'tab-panel-0'} activeIndex={activeTab} index={0}>
                        {currency0 && currency1 && token0Data && token1Data && (
                          <PoolInvest
                            currency0={currency0}
                            currency1={currency1}
                            currency0Price={token0Data.priceUSD}
                            currency1Price={token1Data.priceUSD}
                            oneCurrencyIsWETH={oneCurrencyIsWETH}
                            useEth={useEth}
                            handleUseEth={() => setUseEth((prev) => !prev)}
                          />
                        )}
                      </TabPanel>
                      <TabPanel key={'tab-panel-1'} activeIndex={activeTab} index={1}>
                        {currency0 && currency1 && token0Data && token1Data && (
                          <PoolWithdraw
                            currency0={currency0}
                            currency1={currency1}
                            currency0Price={token0Data.priceUSD}
                            currency1Price={token1Data.priceUSD}
                            oneCurrencyIsWETH={oneCurrencyIsWETH}
                            unwrapEth={useEth}
                            handleUseEth={() => setUseEth((prev) => !prev)}
                          />
                        )}
                      </TabPanel>
                    </AppBody>
                    <AppBody size="md">
                      <PoolDetailChart
                        address={poolData.address}
                        token0={poolData.token0.symbol}
                        token1={poolData.token1.symbol}
                      />
                    </AppBody>
                  </ResponsiveRow>
                  <RowColumn>
                    <TokenStatsWrapper>
                      <TYPE.mediumHeaderEllipsis marginBottom={'1rem'}>
                        <Trans>Token Stats</Trans>
                      </TYPE.mediumHeaderEllipsis>
                      <PoolCardRowColumn>
                        <PoolCardItem style={{ width: '105%' }}>
                          <CurrencyAvatar
                            symbol={poolData.token0.symbol}
                            address={poolData.token0.address}
                            rootStyle={{ marginBottom: '1rem' }}
                            iconProps={{ width: '30', height: '30' }}
                          />
                          <TokenDetails
                            token={poolData.token0 as unknown as ITokenDetail}
                            oppositeSymbol={poolData.token1.symbol}
                          />
                        </PoolCardItem>
                        <PoolCardItem style={{ width: '100%' }}>
                          <CurrencyAvatar
                            symbol={poolData.token1.symbol}
                            address={poolData.token1.address}
                            rootStyle={{ marginBottom: '1rem' }}
                            iconProps={{ width: '30', height: '30' }}
                          />
                          <TokenDetails
                            token={poolData.token1 as unknown as ITokenDetail}
                            oppositeSymbol={poolData.token0.symbol}
                          />
                        </PoolCardItem>
                      </PoolCardRowColumn>
                    </TokenStatsWrapper>
                  </RowColumn>
                </Wrapper>
              </BodyPanel>
            </BodyScroller>
          </>
        ))}
    </>
  )
}

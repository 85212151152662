import React from 'react'
import { Trans } from '@lingui/macro'
import { shortenDecimalValues } from 'utils'
import { ITokenDetail } from 'hooks/useApi'
import { TextItemWrapper, TextValue, TextLabel, TextItemTruncate, SpanTruncate } from 'components/pools/styled'

// @ts-ignore
import MiddleEllipsis from 'react-middle-ellipsis'

import { BaseCurrencyView, ExternalLink } from 'theme'
import { useActiveWeb3React } from 'hooks/web3'
import { getExplorerLink, ExplorerDataType } from 'utils/getExplorerLink'

export type TokenDetailProps = {
  token: ITokenDetail
  oppositeSymbol: string
}

export default function TokenDetail(props: TokenDetailProps) {
  const { token, oppositeSymbol } = props
  const { chainId } = useActiveWeb3React()

  const mcap = parseFloat(token?.mcap)
  const trading = parseFloat(token?.trading)

  return (
    <>
      <TextItemTruncate>
        <TextLabel>
          <Trans>Address</Trans>
        </TextLabel>
        <MiddleEllipsis>
          <ExternalLink href={chainId ? getExplorerLink(chainId, token.address, ExplorerDataType.TOKEN) : ''}>
            <SpanTruncate fontSize="0.95rem">{token.address}</SpanTruncate>
          </ExternalLink>
        </MiddleEllipsis>
      </TextItemTruncate>
      <TextItemWrapper>
        <TextLabel>
          <Trans>Price</Trans>
        </TextLabel>
        <TextValue fontSize={'1rem'}>
          {shortenDecimalValues(token?.price)} {oppositeSymbol}
        </TextValue>
      </TextItemWrapper>
      <TextItemWrapper>
        <TextLabel>
          <Trans>Market Cap</Trans>
        </TextLabel>
        <TextValue fontSize={'1rem'}>
          <BaseCurrencyView type="symbol" value={mcap} numeralFormat={'0 a'} />
        </TextValue>
      </TextItemWrapper>
      <TextItemWrapper>
        <TextLabel>
          <Trans>Trading Volume</Trans>
        </TextLabel>
        <TextValue fontSize={'1rem'}>
          <BaseCurrencyView type="symbol" value={trading} />
        </TextValue>
      </TextItemWrapper>
      <TextItemWrapper>
        <TextLabel>
          <Trans>24h Low / 24h High</Trans>
        </TextLabel>
        <TextValue fontSize={'1rem'}>
          {shortenDecimalValues(token?.priceLow24hUSD)} {oppositeSymbol}
        </TextValue>
        <TextValue fontSize={'1rem'}>
          {shortenDecimalValues(token?.priceHigh24hUSD)} {oppositeSymbol}
        </TextValue>
      </TextItemWrapper>
    </>
  )
}

export type AddressMap = { [chainId: number]: string }

export enum SupportedChainId {
  NAHMII_INTERNAL = 4064,
  NAHMII_TESTNET = 4062,
  NAHMII_MAINNET = 4061,
}

export const DEFAULT_CHAIN_ID = parseInt(window.__RUNTIME_CONFIG__.DEFAULT_CHAIN_ID) as SupportedChainId

if (!Object.values(SupportedChainId).includes(DEFAULT_CHAIN_ID)) {
  throw new Error('Deploy environment default chain not supported')
}

export const SUPPORTED_CHAIN_NAMES: AddressMap = {
  [SupportedChainId.NAHMII_INTERNAL]: 'Nahmii Test',
  [SupportedChainId.NAHMII_TESTNET]: 'Nahmii Internal',
  [SupportedChainId.NAHMII_MAINNET]: 'Nahmii Main',
}

export const ETH_ADDRESS: AddressMap = {
  [DEFAULT_CHAIN_ID]: 'NATIVE',
}

export const NIIFI_ADDRESS: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.NIIFI_ERC20_ADDRESS,
}

export const FACTORY_ADDRESSES: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.FACTORY_ADDRESS,
}
export const ROUTER_ADDRESS: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.ROUTER_ADDRESS,
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const BRIDGE_MAP: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.BRIDGE,
}

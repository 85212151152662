import { gql } from 'urql'
import { TX_PAIR_DATA_FIELDS } from './fragments'

const TX_SWAP_DATA_FIELDS = gql`
  ${TX_PAIR_DATA_FIELDS}
  fragment SwapData on Swap {
    hash: id
    wallet: from
    pair {
      ...PairData
    }
    amountUSD
    amount0In
    amount0Out
    amount1In
    amount1Out
  }
`

const TX_MINT_DATA_FIELDS = gql`
  ${TX_PAIR_DATA_FIELDS}
  fragment MintData on Mint {
    hash: id
    wallet: sender
    pair {
      ...PairData
    }
    amountUSD
    amount0In: amount0
    amount1In: amount1
  }
`

const TX_BURN_DATA_FIELDS = gql`
  ${TX_PAIR_DATA_FIELDS}
  fragment BurnData on Burn {
    hash: id
    wallet: sender
    pair {
      ...PairData
    }
    amountUSD
    amount0In: amount0
    amount1In: amount1
  }
`

export const GetAllTransactions = gql`
  ${TX_SWAP_DATA_FIELDS}
  ${TX_MINT_DATA_FIELDS}
  ${TX_BURN_DATA_FIELDS}
  query ($limit: Int, $offset: Int) {
    transactions(first: $limit, offset: $offset, orderBy: timestamp, orderDirection: desc) {
      id
      timestamp
      swaps {
        ...SwapData
      }
      mints {
        ...MintData
      }
      burns {
        ...BurnData
      }
    }
  }
`

export const GetSwapTransactions = gql`
  ${TX_SWAP_DATA_FIELDS}
  query ($limit: Int, $offset: Int) {
    transactions(
      first: $limit
      offset: $offset
      orderBy: timestamp
      orderDirection: desc
      where: { swaps_: { timestamp_gt: "0" } }
    ) {
      id
      timestamp
      swaps {
        ...SwapData
      }
    }
  }
`

export const GetMintTransactions = gql`
  ${TX_MINT_DATA_FIELDS}
  query ($limit: Int, $offset: Int) {
    transactions(
      first: $limit
      offset: $offset
      orderBy: timestamp
      orderDirection: desc
      where: { mints_: { timestamp_gt: "0" } }
    ) {
      id
      timestamp
      mints {
        ...MintData
      }
    }
  }
`

export const GetBurnTransactions = gql`
  ${TX_BURN_DATA_FIELDS}
  query ($limit: Int, $offset: Int) {
    transactions(
      first: $limit
      offset: $offset
      orderBy: timestamp
      orderDirection: desc
      where: { burns_: { timestamp_gt: "0" } }
    ) {
      id
      timestamp
      burns {
        ...BurnData
      }
    }
  }
`

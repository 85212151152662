import React from 'react'

import { IconProps } from './types'

export function USD({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M13.3916 13.9993H28.0003C28.0003 12.7357 27.8318 11.5115 27.518 10.3471H13.3916V13.9993Z"
          fill="#D80027"
        />
        <path
          d="M13.3916 6.69535H25.9454C25.0884 5.29688 23.9927 4.06078 22.7151 3.04315H13.3916V6.69535Z"
          fill="#D80027"
        />
        <path
          d="M13.9999 27.9994C17.2948 27.9994 20.3232 26.8606 22.7147 24.9559H5.28516C7.67664 26.8606 10.7051 27.9994 13.9999 27.9994Z"
          fill="#D80027"
        />
        <path
          d="M2.05496 21.3041H25.9453C26.6333 20.1815 27.1669 18.9543 27.5178 17.6519H0.482422C0.833352 18.9543 1.36694 20.1815 2.05496 21.3041Z"
          fill="#D80027"
        />
        <path
          d="M6.48506 2.1863H7.76087L6.57415 3.04845L7.02745 4.44347L5.84079 3.58132L4.65412 4.44347L5.04569 3.23832C4.00083 4.10867 3.08503 5.12838 2.33034 6.26456H2.73913L1.98373 6.81335C1.86605 7.00968 1.75317 7.20912 1.645 7.41152L2.00572 8.52173L1.33273 8.03277C1.16545 8.3872 1.01243 8.74962 0.874891 9.11958L1.2723 10.3428H2.73913L1.55241 11.205L2.00572 12.6L0.819055 11.7379L0.108227 12.2543C0.0370781 12.8262 0 13.4088 0 14H14C14 6.26806 14 5.35653 14 0C11.2343 0 8.65621 0.802266 6.48506 2.1863ZM7.02745 12.6L5.84079 11.7379L4.65412 12.6L5.10743 11.205L3.92071 10.3428H5.38754L5.84079 8.94781L6.29404 10.3428H7.76087L6.57415 11.205L7.02745 12.6ZM6.57415 7.12671L7.02745 8.52173L5.84079 7.65959L4.65412 8.52173L5.10743 7.12671L3.92071 6.26456H5.38754L5.84079 4.86954L6.29404 6.26456H7.76087L6.57415 7.12671ZM12.0492 12.6L10.8625 11.7379L9.67586 12.6L10.1292 11.205L8.94244 10.3428H10.4093L10.8625 8.94781L11.3158 10.3428H12.7826L11.5959 11.205L12.0492 12.6ZM11.5959 7.12671L12.0492 8.52173L10.8625 7.65959L9.67586 8.52173L10.1292 7.12671L8.94244 6.26456H10.4093L10.8625 4.86954L11.3158 6.26456H12.7826L11.5959 7.12671ZM11.5959 3.04845L12.0492 4.44347L10.8625 3.58132L9.67586 4.44347L10.1292 3.04845L8.94244 2.1863H10.4093L10.8625 0.791273L11.3158 2.1863H12.7826L11.5959 3.04845Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function CNY({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#D80027"
      />
      <path
        d="M7.66172 8.52051L8.87031 12.2393H12.7805L9.61953 14.5416L10.8281 18.2604L7.66172 15.9635L4.49531 18.2604L5.70938 14.5416L2.54297 12.2393H6.45313L7.66172 8.52051Z"
        fill="#FFDA44"
      />
      <path
        d="M16.5975 21.6837L15.6732 20.5462L14.3061 21.0767L15.099 19.8462L14.1748 18.7032L15.5912 19.0806L16.3896 17.8501L16.4662 19.3157L17.8881 19.6931L16.5154 20.2181L16.5975 21.6837Z"
        fill="#FFDA44"
      />
      <path
        d="M18.435 18.3478L18.8725 16.9478L17.6748 16.1001L19.1404 16.0782L19.5725 14.6782L20.0482 16.0673L21.5139 16.0509L20.3381 16.9259L20.8084 18.3149L19.6107 17.4673L18.435 18.3478Z"
        fill="#FFDA44"
      />
      <path
        d="M20.9127 10.2761L20.2674 11.594L21.3174 12.6167L19.8682 12.4089L19.2229 13.7214L18.9713 12.2776L17.5166 12.0698L18.8182 11.3862L18.5666 9.93701L19.6166 10.9597L20.9127 10.2761Z"
        fill="#FFDA44"
      />
      <path
        d="M16.636 6.28369L16.5267 7.74385L17.8884 8.29619L16.461 8.64619L16.3571 10.1118L15.586 8.86494L14.1587 9.21494L15.1048 8.09385L14.3282 6.85244L15.6899 7.40479L16.636 6.28369Z"
        fill="#FFDA44"
      />
    </svg>
  )
}
export function EUR({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#0052B4"
      />
      <path
        d="M14.0001 5.47842L14.4535 6.87345H15.9202L14.7335 7.7356L15.1868 9.13063L14.0001 8.26842L12.8134 9.13063L13.2667 7.7356L12.0801 6.87345H13.5468L14.0001 5.47842Z"
        fill="#FFDA44"
      />
      <path
        d="M7.97419 7.97476L9.28122 8.64064L10.3184 7.60355L10.0888 9.05222L11.3958 9.71815L9.9471 9.94762L9.71763 11.3964L9.0517 10.0894L7.60303 10.3189L8.64018 9.2818L7.97419 7.97476Z"
        fill="#FFDA44"
      />
      <path
        d="M5.47852 14.0003L6.87354 13.547V12.0803L7.73563 13.267L9.13071 12.8137L8.26845 14.0003L9.13071 15.187L7.73563 14.7338L6.87354 15.9204V14.4537L5.47852 14.0003Z"
        fill="#FFDA44"
      />
      <path
        d="M7.97419 20.0257L8.64012 18.7186L7.60303 17.6815L9.05175 17.9111L9.71757 16.6041L9.9471 18.0528L11.3958 18.2823L10.089 18.9482L10.3184 20.3969L9.28122 19.3597L7.97419 20.0257Z"
        fill="#FFDA44"
      />
      <path
        d="M14.0001 22.5218L13.5468 21.1268H12.0801L13.2668 20.2646L12.8134 18.8697L14.0001 19.7318L15.1868 18.8697L14.7335 20.2646L15.9202 21.1268H14.4534L14.0001 22.5218Z"
        fill="#FFDA44"
      />
      <path
        d="M20.026 20.0257L18.719 19.3598L17.6818 20.3969L17.9114 18.9482L16.6045 18.2823L18.0532 18.0528L18.2826 16.6041L18.9485 17.9111L20.3972 17.6815L19.36 18.7188L20.026 20.0257Z"
        fill="#FFDA44"
      />
      <path
        d="M22.5217 14.0003L21.1267 14.4537V15.9204L20.2645 14.7337L18.8696 15.187L19.7318 14.0003L18.8696 12.8137L20.2646 13.267L21.1267 12.0803V13.5471L22.5217 14.0003Z"
        fill="#FFDA44"
      />
      <path
        d="M20.026 7.9748L19.3601 9.28183L20.3972 10.319L18.9485 10.0894L18.2826 11.3964L18.0532 9.94765L16.6045 9.71813L17.9114 9.05226L17.6818 7.60364L18.7191 8.64073L20.026 7.9748Z"
        fill="#FFDA44"
      />
    </svg>
  )
}
export function INR({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M14 0C8.44953 0 3.65366 3.23012 1.38916 7.91306H26.6108C24.3463 3.23012 19.5504 0 14 0Z"
          fill="#FF9811"
        />
        <path
          d="M14 28.0003C19.5504 28.0003 24.3463 24.7701 26.6108 20.0872H1.38916C3.65366 24.7701 8.44953 28.0003 14 28.0003Z"
          fill="#6DA544"
        />
        <path
          d="M13.9999 18.8695C16.6893 18.8695 18.8694 16.6893 18.8694 14C18.8694 11.3106 16.6893 9.13043 13.9999 9.13043C11.3105 9.13043 9.13037 11.3106 9.13037 14C9.13037 16.6893 11.3105 18.8695 13.9999 18.8695Z"
          fill="#0052B4"
        />
        <path
          d="M14 17.0438C15.6809 17.0438 17.0435 15.6812 17.0435 14.0003C17.0435 12.3195 15.6809 10.9568 14 10.9568C12.3191 10.9568 10.9565 12.3195 10.9565 14.0003C10.9565 15.6812 12.3191 17.0438 14 17.0438Z"
          fill="#F0F0F0"
        />
        <path
          d="M14 10.2442L14.9389 12.3736L17.2524 12.122L15.8778 13.9998L17.2524 15.8776L14.9389 15.626L14 17.7554L13.0611 15.626L10.7476 15.8776L12.1222 13.9998L10.7476 12.122L13.0611 12.3736L14 10.2442Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function CAD({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.9999 14.0001C27.9999 8.44969 24.7698 3.65381 20.0869 1.38931V26.6109C24.7698 24.3465 27.9999 19.5506 27.9999 14.0001Z"
        fill="#D80027"
      />
      <path
        d="M0 14.0001C0 19.5506 3.23012 24.3465 7.91306 26.611V1.38931C3.23012 3.65381 0 8.44969 0 14.0001Z"
        fill="#D80027"
      />
      <path
        d="M16.4347 15.8262L18.8694 14.6088L17.6521 14.0001V12.7827L15.2173 14.0001L16.4347 11.5653H15.2173L13.9999 9.73923L12.7825 11.5653H11.5651L12.7825 14.0001L10.3477 12.7827V14.0001L9.13037 14.6088L11.5651 15.8262L10.9564 17.0436H13.3912V18.8696H14.6086V17.0436H17.0434L16.4347 15.8262Z"
        fill="#D80027"
      />
    </svg>
  )
}
export function GBP({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M2.89371 5.47687C1.794 6.90766 0.96477 8.55676 0.481934 10.3483H7.76511L2.89371 5.47687Z"
        fill="#0052B4"
      />
      <path
        d="M27.5179 10.3479C27.0351 8.5564 26.2058 6.90729 25.1062 5.4765L20.2349 10.3479H27.5179Z"
        fill="#0052B4"
      />
      <path
        d="M0.481934 17.6525C0.964824 19.444 1.79405 21.0931 2.89371 22.5238L7.76494 17.6525H0.481934Z"
        fill="#0052B4"
      />
      <path
        d="M22.5232 2.89425C21.0924 1.79454 19.4434 0.965312 17.6519 0.482422V7.76554L22.5232 2.89425Z"
        fill="#0052B4"
      />
      <path
        d="M5.47607 25.106C6.90686 26.2057 8.55596 27.0349 10.3474 27.5178V20.2347L5.47607 25.106Z"
        fill="#0052B4"
      />
      <path
        d="M10.3474 0.482422C8.55591 0.965312 6.90681 1.79454 5.47607 2.8942L10.3474 7.76548V0.482422Z"
        fill="#0052B4"
      />
      <path
        d="M17.6519 27.5178C19.4433 27.0349 21.0924 26.2057 22.5231 25.106L17.6519 20.2347V27.5178Z"
        fill="#0052B4"
      />
      <path
        d="M20.2349 17.6525L25.1062 22.5239C26.2058 21.0931 27.0351 19.444 27.5179 17.6525H20.2349Z"
        fill="#0052B4"
      />
      <path
        d="M27.8815 12.1739H15.8262H15.8261V0.118508C15.2283 0.0406875 14.6189 0 14 0C13.381 0 12.7717 0.0406875 12.1739 0.118508V12.1738V12.1739H0.118508C0.0406875 12.7717 0 13.3811 0 14C0 14.619 0.0406875 15.2283 0.118508 15.8261H12.1738H12.1739V27.8815C12.7717 27.9593 13.381 28 14 28C14.6189 28 15.2283 27.9594 15.8261 27.8815V15.8262V15.8261H27.8815C27.9593 15.2283 28 14.619 28 14C28 13.3811 27.9593 12.7717 27.8815 12.1739Z"
        fill="#D80027"
      />
      <path
        d="M17.6519 17.652L23.8991 23.8993C24.1865 23.6121 24.4606 23.3118 24.7221 23.0005L19.3735 17.652H17.6519V17.652Z"
        fill="#D80027"
      />
      <path
        d="M10.3474 17.6521H10.3473L4.1001 23.8993C4.38732 24.1866 4.6876 24.4607 4.99889 24.7222L10.3474 19.3736V17.6521Z"
        fill="#D80027"
      />
      <path
        d="M10.3481 10.3479V10.3478L4.10077 4.10049C3.81344 4.38771 3.53935 4.688 3.27783 4.99928L8.62643 10.3479H10.3481V10.3479Z"
        fill="#D80027"
      />
      <path
        d="M17.6519 10.3478L23.8992 4.10044C23.612 3.81311 23.3117 3.53902 23.0004 3.27756L17.6519 8.62616V10.3478Z"
        fill="#D80027"
      />
    </svg>
  )
}
export function JPY({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M14 20.0867C17.3618 20.0867 20.087 17.3615 20.087 13.9997C20.087 10.638 17.3618 7.91278 14 7.91278C10.6383 7.91278 7.91309 10.638 7.91309 13.9997C7.91309 17.3615 10.6383 20.0867 14 20.0867Z"
        fill="#D80027"
      />
    </svg>
  )
}
export function RUB({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M27.1292 18.8695C27.692 17.3529 28 15.7125 28 14C28 12.2875 27.692 10.6471 27.1292 9.13043H0.870789C0.308055 10.6471 0 12.2875 0 14C0 15.7125 0.308055 17.3529 0.870789 18.8695L14 20.0869L27.1292 18.8695Z"
          fill="#0052B4"
        />
        <path
          d="M13.9998 28C20.0193 28 25.1509 24.2008 27.129 18.8696H0.870605C2.84871 24.2008 7.98031 28 13.9998 28Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function MXN({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M28.0005 13.9999C28.0005 8.44944 24.7703 3.65357 20.0874 1.38907V26.6107C24.7703 24.3462 28.0005 19.5504 28.0005 13.9999Z"
          fill="#D80027"
        />
        <path
          d="M0 13.9999C0 19.5504 3.23012 24.3462 7.91306 26.6107V1.38907C3.23012 3.65357 0 8.44944 0 13.9999Z"
          fill="#6DA544"
        />
        <path
          d="M10.3481 13.9997C10.3481 16.0168 11.9833 17.6519 14.0003 17.6519C16.0174 17.6519 17.6525 16.0168 17.6525 13.9997V12.7823H10.3481V13.9997Z"
          fill="#6DA544"
        />
        <path
          d="M18.8695 11.5654H15.2174C15.2174 10.8931 14.6723 10.348 14 10.348C13.3276 10.348 12.7826 10.8931 12.7826 11.5654H9.13037C9.13037 12.2378 9.71602 12.7828 10.3883 12.7828H10.3478C10.3478 13.4552 10.8928 14.0002 11.5652 14.0002C11.5652 14.6726 12.1102 15.2176 12.7826 15.2176H15.2174C15.8897 15.2176 16.4348 14.6726 16.4348 14.0002C17.1071 14.0002 17.6521 13.4552 17.6521 12.7828H17.6116C18.284 12.7828 18.8695 12.2378 18.8695 11.5654Z"
          fill="#FF9811"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function CHF({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#D80027"
      />
      <path
        d="M21.305 11.5657H16.4354V6.69608H11.5658V11.5657H6.69629V16.4352H11.5658V21.3047H16.4354V16.4352H21.305V11.5657Z"
        fill="#F0F0F0"
      />
    </svg>
  )
}
export function KRW({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.8694 14C18.8694 15.2174 16.6893 18.2608 13.9999 18.2608C11.3105 18.2608 9.13037 15.2174 9.13037 14C9.13037 11.3105 11.3105 9.13043 13.9999 9.13043C16.6893 9.13043 18.8694 11.3105 18.8694 14Z"
        fill="#D80027"
      />
      <path
        d="M18.8694 14C18.8694 16.6894 16.6893 18.8695 13.9999 18.8695C11.3105 18.8695 9.13037 16.6894 9.13037 14"
        fill="#0052B4"
      />
      <path d="M19.1606 18.3042L20.4517 17.0132L21.3124 17.8739L20.0213 19.1649L19.1606 18.3042Z" fill="black" />
      <path d="M17.0083 20.4562L18.2993 19.1652L19.16 20.0259L17.869 21.3169L17.0083 20.4562Z" fill="black" />
      <path d="M21.7432 20.8863L23.0342 19.5952L23.8949 20.4559L22.6039 21.747L21.7432 20.8863Z" fill="black" />
      <path d="M19.5918 23.0382L20.8828 21.7472L21.7435 22.6079L20.4525 23.8989L19.5918 23.0382Z" fill="black" />
      <path d="M20.4526 19.5954L21.7437 18.3043L22.6044 19.165L21.3133 20.4561L20.4526 19.5954Z" fill="black" />
      <path d="M18.2998 21.7471L19.5908 20.456L20.4515 21.3167L19.1605 22.6078L18.2998 21.7471Z" fill="black" />
      <path d="M21.7495 9.69788L18.3067 6.25511L19.1674 5.39441L22.6102 8.83718L21.7495 9.69788Z" fill="black" />
      <path d="M18.3057 8.83783L17.0146 7.5468L17.8753 6.68614L19.1663 7.97717L18.3057 8.83783Z" fill="black" />
      <path d="M20.4575 10.9898L19.1665 9.69878L20.0272 8.83811L21.3182 10.1291L20.4575 10.9898Z" fill="black" />
      <path d="M20.8867 6.25504L19.5957 4.964L20.4564 4.1033L21.7474 5.39433L20.8867 6.25504Z" fill="black" />
      <path d="M23.04 8.40771L21.749 7.11668L22.6097 6.25598L23.9007 7.54701L23.04 8.40771Z" fill="black" />
      <path d="M4.96338 19.5929L8.40614 23.0356L7.54544 23.8963L4.10268 20.4536L4.96338 19.5929Z" fill="black" />
      <path d="M8.40527 20.4529L9.6963 21.744L8.83564 22.6046L7.54462 21.3136L8.40527 20.4529Z" fill="black" />
      <path d="M6.25342 18.3015L7.54445 19.5926L6.68375 20.4533L5.39272 19.1622L6.25342 18.3015Z" fill="black" />
      <path d="M7.54541 17.0103L10.9882 20.453L10.1275 21.3137L6.68471 17.871L7.54541 17.0103Z" fill="black" />
      <path d="M8.40576 4.9614L4.963 8.40416L4.1023 7.54346L7.54506 4.10069L8.40576 4.9614Z" fill="black" />
      <path d="M9.69678 6.2522L6.25401 9.69497L5.39331 8.83426L8.83608 5.3915L9.69678 6.2522Z" fill="black" />
      <path d="M10.9883 7.54422L7.54552 10.987L6.68485 10.1263L10.1276 6.68356L10.9883 7.54422Z" fill="black" />
    </svg>
  )
}
export function TRY({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#D80027"
      />
      <path
        d="M13.4265 11.4394L14.5752 13.0223L16.4355 12.4191L15.285 14.0007L16.4336 15.5835L14.5739 14.978L13.4234 16.5596L13.4246 14.6039L11.5649 13.9984L13.4253 13.3952L13.4265 11.4394Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.2917 17.9565C8.10657 17.9565 6.33513 16.185 6.33513 13.9999C6.33513 11.8148 8.10657 10.0434 10.2917 10.0434C10.973 10.0434 11.614 10.2157 12.1737 10.519C11.2957 9.66026 10.0949 9.13034 8.76993 9.13034C6.08051 9.13034 3.90039 11.3105 3.90039 13.9999C3.90039 16.6892 6.08056 18.8694 8.76993 18.8694C10.095 18.8694 11.2957 18.3394 12.1737 17.4808C11.614 17.7841 10.973 17.9565 10.2917 17.9565Z"
        fill="#F0F0F0"
      />
    </svg>
  )
}
export function BRL({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#6DA544"
      />
      <path d="M14.0003 5.47839L25.5655 14.0001L14.0003 22.5219L2.43506 14.0001L14.0003 5.47839Z" fill="#FFDA44" />
      <path
        d="M13.9999 18.8695C16.6893 18.8695 18.8694 16.6893 18.8694 14C18.8694 11.3106 16.6893 9.13043 13.9999 9.13043C11.3105 9.13043 9.13037 11.3106 9.13037 14C9.13037 16.6893 11.3105 18.8695 13.9999 18.8695Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.5655 13.696C10.7188 13.696 9.90156 13.8248 9.13232 14.0638C9.16639 16.7238 11.3322 18.8699 14.0003 18.8699C15.6501 18.8699 17.1072 18.0487 17.9881 16.7936C16.4811 14.907 14.1623 13.696 11.5655 13.696Z"
        fill="#0052B4"
      />
      <path
        d="M18.78 14.9329C18.8386 14.6308 18.8703 14.3192 18.8703 14C18.8703 11.3105 16.6901 9.13043 14.0007 9.13043C11.994 9.13043 10.2714 10.3445 9.52588 12.078C10.1849 11.9415 10.8671 11.8696 11.566 11.8696C14.3943 11.8695 16.9529 13.0452 18.78 14.9329Z"
        fill="#0052B4"
      />
    </svg>
  )
}
export function SEK({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#FFDA44"
        />
        <path
          d="M10.9566 12.1739H27.8815C26.9867 5.3048 21.1131 0 14 0C12.9547 0 11.9365 0.115555 10.9565 0.332773V12.1739H10.9566Z"
          fill="#0052B4"
        />
        <path
          d="M7.30448 12.1739V1.7023C3.46028 3.79979 0.708348 7.64711 0.118652 12.174H7.30448V12.1739Z"
          fill="#0052B4"
        />
        <path
          d="M7.30443 15.8264H0.118652C0.708348 20.3533 3.46028 24.2006 7.30448 26.298L7.30443 15.8264Z"
          fill="#0052B4"
        />
        <path
          d="M10.9565 15.8265V27.6676C11.9365 27.8848 12.9547 28.0003 14 28.0003C21.1131 28.0003 26.9867 22.6955 27.8815 15.8264H10.9565V15.8265Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function HKD({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#D80027"
      />
      <path
        d="M15.4453 10.5915C15.1272 11.9149 14.5619 11.6625 14.2844 12.8166C12.6502 12.4237 11.6437 10.7803 12.0367 9.14601C12.4294 7.51179 14.073 6.50532 15.7072 6.89825C15.1523 9.20644 15.73 9.40791 15.4453 10.5915Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.2048 11.5721C12.3651 12.2836 11.9503 12.7434 12.9622 13.3639C12.0834 14.7968 10.2096 15.246 8.77665 14.3674C7.34378 13.4887 6.89442 11.6147 7.77313 10.1819C9.7969 11.4228 10.1669 10.9357 11.2048 11.5721Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.8269 15.9081C11.8621 15.0244 12.1711 15.5609 13.0739 14.7903C14.1652 16.0688 14.0134 17.9898 12.735 19.0811C11.4565 20.1724 9.53546 20.0205 8.44434 18.7421C10.2498 17.2009 9.90083 16.6983 10.8269 15.9081Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.8341 17.608C14.3135 16.3504 14.9192 16.2222 14.4653 15.1256C16.0184 14.4828 17.7986 15.2208 18.4414 16.7738C19.0841 18.3269 18.3461 20.107 16.793 20.7498C15.8853 18.5564 15.2997 18.733 14.8341 17.608Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.6886 14.3218C16.3316 14.4282 16.3967 13.8125 15.2136 13.9053C15.0822 12.2298 16.3342 10.7646 18.0099 10.6331C19.6855 10.5019 21.1506 11.7538 21.2819 13.4295C18.9152 13.6151 18.9021 14.2267 17.6886 14.3218Z"
        fill="#F0F0F0"
      />
    </svg>
  )
}
export function AUD({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M28 14C28 21.7319 21.7319 28 14 28C6.26806 28 0 21.7319 0 14C0 14.0033 14 0.00158594 14 0C21.7319 0 28 6.26806 28 14Z"
        fill="#0052B4"
      />
      <path d="M13.9999 0C13.9991 0 13.9983 5.46875e-05 13.9976 5.46875e-05L13.9999 0Z" fill="#F0F0F0" />
      <path
        d="M13.9624 14H13.9999C13.9999 13.9874 13.9999 13.9752 13.9999 13.9626C13.9874 13.9751 13.9749 13.9876 13.9624 14Z"
        fill="#F0F0F0"
      />
      <path
        d="M14 7.3044C14 4.841 14 3.22679 14 6.10352e-05H13.9976C6.26675 0.00131885 0 6.26878 0 14H7.30434V9.88685L11.4175 14H13.9626C13.9751 13.9875 13.9876 13.9751 14.0001 13.9625C14.0001 13.0194 14.0001 12.1778 14.0001 11.4176L9.88684 7.3044H14Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.08295 1.82599C4.89332 3.07281 3.07299 4.89314 1.82617 7.08277V13.9999H5.47837V5.47824V5.47818H14.0001C14.0001 4.3263 14.0001 3.22894 14.0001 1.82599H7.08295Z"
        fill="#D80027"
      />
      <path
        d="M14.0003 12.2783L9.0263 7.30441H7.30469C7.30469 7.30435 7.30469 7.30441 7.30469 7.30441L14.0002 14H14.0003C14.0003 14 14.0003 12.813 14.0003 12.2783Z"
        fill="#D80027"
      />
      <path
        d="M8.4435 16.4348L9.21186 18.0414L10.9469 17.6404L10.1699 19.2429L11.5652 20.3493L9.82797 20.7409L9.83284 22.5218L8.4435 21.4076L7.05422 22.5218L7.05909 20.7409L5.32178 20.3493L6.71713 19.2429L5.94002 17.6404L7.6752 18.0414L8.4435 16.4348Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.9607 19.4785L21.3449 20.2818L22.2125 20.0812L21.8239 20.8824L22.5216 21.4357L21.653 21.6315L21.6554 22.5219L20.9607 21.9648L20.2661 22.5219L20.2685 21.6315L19.3999 21.4357L20.0976 20.8824L19.709 20.0812L20.5766 20.2818L20.9607 19.4785Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.3871 10.9564L17.7712 11.7598L18.6388 11.5592L18.2503 12.3604L18.9479 12.9137L18.0793 13.1095L18.0816 13.9999L17.3871 13.4428L16.6924 13.9999L16.6948 13.1095L15.8262 12.9137L16.5238 12.3604L16.1353 11.5592L17.0029 11.7598L17.3871 10.9564Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.9607 6.08698L21.3449 6.89033L22.2125 6.6898L21.824 7.49102L22.5216 8.04424L21.653 8.24008L21.6554 9.1305L20.9607 8.5734L20.2661 9.1305L20.2685 8.24008L19.3999 8.04424L20.0975 7.49102L19.709 6.6898L20.5766 6.89033L20.9607 6.08698Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.0824 9.7392L24.4665 10.5426L25.3341 10.342L24.9456 11.1432L25.6432 11.6965L24.7746 11.8923L24.777 12.7827L24.0824 12.2256L23.3877 12.7827L23.3901 11.8923L22.5215 11.6965L23.2191 11.1432L22.8306 10.342L23.6981 10.5426L24.0824 9.7392Z"
        fill="#F0F0F0"
      />
      <path
        d="M21.8504 14L22.1525 14.93H23.1305L22.3393 15.5048L22.6415 16.4348L21.8504 15.86L21.0593 16.4348L21.3614 15.5048L20.5703 14.93H21.5482L21.8504 14Z"
        fill="#F0F0F0"
      />
    </svg>
  )
}
export function NOK({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path d="M0.482422 17.652C1.29245 20.6575 3.07773 23.2625 5.4784 25.1071V17.652H0.482422Z" fill="#D80027" />
        <path
          d="M12.7827 27.9465C13.184 27.9811 13.5898 27.9998 14.0001 27.9998C20.4685 27.9998 25.9113 23.6127 27.5178 17.652H12.7827V27.9465Z"
          fill="#D80027"
        />
        <path
          d="M27.5178 10.3478C25.9113 4.38709 20.4685 0 14.0001 0C13.5898 0 13.184 0.0187031 12.7827 0.0532656V10.3478H27.5178Z"
          fill="#D80027"
        />
        <path d="M5.4784 2.8927C3.07773 4.73731 1.29245 7.34224 0.482422 10.3478H5.4784V2.8927Z" fill="#D80027" />
        <path
          d="M27.8815 12.174H10.9566H10.9565V0.332825C9.66273 0.619715 8.43615 1.08472 7.30434 1.70231V12.1739V12.1739H0.118508C0.0405781 12.7717 0 13.3812 0 14.0001C0 14.6189 0.0405781 15.2284 0.118508 15.8261H7.30428H7.30434V26.2977C8.43615 26.9153 9.66273 27.3804 10.9565 27.6672V15.8263V15.8262H27.8815C27.9593 15.2284 28 14.6189 28 14.0001C28 13.3812 27.9593 12.7717 27.8815 12.174Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function SGD({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#F0F0F0"
      />
      <path d="M0 14.0001C0 6.26806 6.26806 0 14 0C21.7319 0 28 6.26806 28 14.0001" fill="#D80027" />
      <path
        d="M8.52192 7.30396C8.52192 5.26423 9.95544 3.56028 11.8697 3.14219C11.5755 3.07799 11.2703 3.04315 10.9567 3.04315C8.60341 3.04315 6.6958 4.95076 6.6958 7.30402C6.6958 9.65728 8.60341 11.5649 10.9567 11.5649C11.2702 11.5649 11.5755 11.5301 11.8697 11.4658C9.95544 11.0477 8.52192 9.34375 8.52192 7.30396Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.0002 3.34711L14.3024 4.27707H15.2803L14.4891 4.85189L14.7913 5.78185L14.0002 5.20714L13.2091 5.78185L13.5113 4.85189L12.7202 4.27707H13.698L14.0002 3.34711Z"
        fill="#F0F0F0"
      />
      <path
        d="M11.6278 5.17352L11.9299 6.10354H12.9078L12.1167 6.6783L12.4189 7.60826L11.6278 7.03355L10.8366 7.60826L11.1388 6.6783L10.3477 6.10354H11.3255L11.6278 5.17352Z"
        fill="#F0F0F0"
      />
      <path
        d="M16.3723 5.17352L16.6746 6.10354H17.6524L16.8612 6.6783L17.1635 7.60826L16.3723 7.03355L15.5812 7.60826L15.8834 6.6783L15.0923 6.10354H16.0701L16.3723 5.17352Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.4597 7.91272L15.7619 8.84274H16.7397L15.9486 9.4175L16.2508 10.3475L15.4597 9.77275L14.6686 10.3475L14.9708 9.4175L14.1797 8.84274H15.1574L15.4597 7.91272Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.5418 7.91272L12.8439 8.84274H13.8219L13.0307 9.4175L13.333 10.3475L12.5418 9.77275L11.7506 10.3475L12.0528 9.4175L11.2617 8.84274H12.2396L12.5418 7.91272Z"
        fill="#F0F0F0"
      />
    </svg>
  )
}
export function BTC({ width = 28, height = 28, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M27.5599 17.4072C25.6901 24.9072 18.0938 29.4716 10.5928 27.6014C3.09499 25.7316 -1.46941 18.1347 0.401213 10.6353C2.27019 3.13437 9.86655 -1.43036 17.3652 0.439436C24.8656 2.30924 29.4297 9.90689 27.5597 17.4073L27.5599 17.4072H27.5599Z"
          fill="#F7931A"
        />
        <path
          d="M20.1732 12.0054C20.4519 10.1423 19.0334 9.14081 17.0938 8.47272L17.723 5.949L16.1868 5.5662L15.5743 8.02347C15.1704 7.92274 14.7556 7.82783 14.3434 7.73374L14.9604 5.26024L13.425 4.87744L12.7955 7.40035C12.4613 7.32425 12.133 7.24904 11.8145 7.1698L11.8163 7.16186L9.69776 6.63283L9.28909 8.27366C9.28909 8.27366 10.4289 8.53493 10.4049 8.55101C11.027 8.70628 11.1395 9.11809 11.1208 9.44451L10.404 12.3196C10.4469 12.3305 10.5024 12.3462 10.5638 12.3709C10.5125 12.3582 10.458 12.3443 10.4014 12.3307L9.39674 16.3583C9.32071 16.5473 9.12774 16.831 8.69279 16.7233C8.70819 16.7456 7.57621 16.4446 7.57621 16.4446L6.81348 18.2032L8.81268 18.7015C9.1846 18.7948 9.54906 18.8924 9.90798 18.9842L9.27226 21.5368L10.8068 21.9196L11.4363 19.3941C11.8555 19.5079 12.2623 19.6128 12.6606 19.7118L12.0332 22.2254L13.5695 22.6082L14.2052 20.0604C16.8248 20.5561 18.7946 20.3563 19.6237 17.9868C20.2918 16.0791 19.5904 14.9787 18.2123 14.2611C19.2161 14.0296 19.9722 13.3694 20.1738 12.0056L20.1733 12.0053L20.1732 12.0054ZM16.6632 16.9273C16.1884 18.835 12.9764 17.8038 11.935 17.5452L12.7787 14.1634C13.82 14.4233 17.1594 14.9378 16.6633 16.9273H16.6632ZM17.1383 11.9777C16.7052 13.713 14.0318 12.8314 13.1646 12.6152L13.9295 9.54811C14.7967 9.76428 17.5895 10.1678 17.1384 11.9777H17.1383Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function ETH({ width = 30, height = 30, ...rest }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="15" cy="15" r="14.5" fill="white" stroke="white" />
      <g clipPath="url(#clip0)">
        <path
          d="M15.0312 4.6138V4.62414C15.0415 4.67587 15.0415 4.73794 15.0415 4.80001V12.2586C15.0312 12.3 15.0002 12.3104 14.9691 12.331C14.7519 12.4345 14.545 12.5276 14.3277 12.6207C14.0277 12.7552 13.7174 12.9 13.4174 13.0345L12.3312 13.531C12.0312 13.6655 11.7312 13.8 11.4415 13.9345C11.0898 14.1 10.7277 14.2552 10.376 14.4207C10.076 14.5552 9.77602 14.7 9.46568 14.8345C9.2174 14.9483 8.96913 15.0517 8.7312 15.1655C8.71051 15.1759 8.68982 15.1862 8.66913 15.1862C8.65878 15.1862 8.65878 15.1862 8.64844 15.1759L8.92775 14.7104C9.46568 13.8207 9.99327 12.9414 10.5312 12.0517C11.1002 11.1 11.6795 10.1483 12.2484 9.19656C12.776 8.31725 13.314 7.43794 13.8415 6.55863C14.2243 5.91725 14.6174 5.27587 15.0002 4.63449C15.0105 4.6138 15.0209 4.60345 15.0209 4.58276H15.0312C15.0209 4.59311 15.0312 4.60345 15.0312 4.6138Z"
          fill="#8A92B2"
        />
        <path
          d="M21.3827 15.1759L21.393 15.1862L19.8723 16.0862L15.093 18.9103C15.0723 18.9207 15.0516 18.931 15.0413 18.9414C15.0103 18.9414 15.0103 18.9103 15.0103 18.9V18.8069V12.4138C15.0103 12.3828 15.0103 12.3414 15.0206 12.3103C15.0309 12.269 15.062 12.2793 15.093 12.2896C15.2275 12.3517 15.3723 12.4138 15.5068 12.4759C15.9103 12.6621 16.3137 12.8483 16.7172 13.0241C17.0689 13.1793 17.4103 13.3448 17.762 13.5C18.1137 13.6552 18.4654 13.8207 18.8172 13.9759C19.1172 14.1103 19.4275 14.2552 19.7275 14.3896C20.0275 14.5241 20.3378 14.669 20.6378 14.8034C20.8758 14.9069 21.1137 15.0207 21.3516 15.1241C21.3516 15.1552 21.362 15.1655 21.3827 15.1759Z"
          fill="#454A75"
        />
        <path
          d="M15.0307 25.3655C15.0307 25.3759 15.0204 25.3862 15.0204 25.3965H15.01C15.01 25.3759 14.9893 25.3655 14.979 25.3448C14.3376 24.4448 13.6962 23.5345 13.0548 22.6345C12.4031 21.7138 11.7411 20.7828 11.0893 19.8621C10.4583 18.9724 9.81691 18.0724 9.18588 17.1828C9.02036 16.9448 8.85485 16.7172 8.68933 16.4793C8.67898 16.4586 8.66864 16.4483 8.64795 16.4172C8.67898 16.4172 8.69967 16.4379 8.71002 16.4483C9.61002 16.9759 10.4997 17.5034 11.3997 18.031C12.4342 18.6414 13.4583 19.2517 14.4928 19.8621L15.0204 20.1724C15.0411 20.1931 15.0411 20.2138 15.0411 20.2345V25.2207C15.0411 25.2724 15.0411 25.3241 15.0307 25.3655Z"
          fill="#8A92B2"
        />
        <path
          d="M8.6377 15.1966V15.1862C8.96873 15.0414 9.28942 14.8862 9.62045 14.7414C10.0446 14.5448 10.4687 14.3586 10.8929 14.1621C11.2136 14.0173 11.5446 13.8621 11.8653 13.7173C12.3411 13.5 12.8067 13.2931 13.2825 13.0759C13.6032 12.9311 13.9239 12.7862 14.2549 12.6311C14.4825 12.5276 14.7205 12.4242 14.948 12.3207C14.9687 12.3104 14.9998 12.3 15.0101 12.2793C15.0205 12.2793 15.0205 12.2897 15.0101 12.3V18.869C15.0101 18.9 14.9998 18.9311 15.0205 18.9517C14.9998 18.9828 14.9791 18.9517 14.9687 18.9414C14.8756 18.8897 14.7825 18.8379 14.6894 18.7759C12.6929 17.5966 10.686 16.4069 8.68942 15.2276C8.67907 15.2173 8.65838 15.2069 8.6377 15.1966Z"
          fill="#62688F"
        />
        <path
          d="M21.3619 16.4172H21.3722C21.3722 16.4379 21.3515 16.4586 21.3412 16.4793C19.4377 19.1586 17.5343 21.8483 15.6309 24.5276C15.4343 24.8069 15.2274 25.0862 15.0309 25.3655C15.0205 25.3552 15.0205 25.3448 15.0205 25.3345V25.2724V20.2552V20.1621C15.455 19.9034 15.8791 19.6552 16.3136 19.3965C17.9895 18.4034 19.6653 17.4207 21.3309 16.4276C21.3412 16.4379 21.3515 16.4276 21.3619 16.4172Z"
          fill="#62688F"
        />
        <path
          d="M15.0207 12.3V12.2793V12.2172V4.7069C15.0207 4.67587 15.0103 4.65518 15.031 4.62415C17.131 8.11035 19.231 11.5862 21.3207 15.0724C21.3414 15.1035 21.3724 15.1448 21.3828 15.1862C21.2379 15.1345 21.1034 15.0621 20.969 15C20.8034 14.9276 20.6276 14.8448 20.4621 14.7724C20.3586 14.7207 20.2448 14.6793 20.1414 14.6276C19.9655 14.5448 19.7897 14.4724 19.6138 14.3897C19.5103 14.3483 19.4069 14.2966 19.3034 14.2448L18.6207 13.9345C18.5069 13.8828 18.3931 13.831 18.269 13.7793L17.7724 13.5621C17.669 13.5207 17.5655 13.469 17.4621 13.4172L16.7793 13.1069C16.6655 13.0552 16.5517 13.0035 16.4276 12.9517L15.931 12.7345C15.8172 12.6828 15.7138 12.631 15.6 12.5793C15.4034 12.4862 15.2069 12.3931 15 12.3104C15.031 12.3 15.0207 12.3 15.0207 12.3Z"
          fill="#62688F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="21" height="21" fill="white" transform="translate(4.5 4.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { gql } from 'urql'

export const GetFactoryStats = gql`
  query ($limit: Int) {
    niifiDayDatas(first: $limit, orderBy: date, orderDirection: desc) {
      date
      tvl: totalLiquidityUSD
      volume: dailyVolumeUSD
      fees: dailyFeesUSD
    }
  }
`

export const GetFactoryChartData = gql`
  query ($limit: Int, $ts: Int) {
    niifiDayDatas(orderBy: date, orderDirection: asc, where: { date_gt: $ts }) {
      date
      tvl: totalLiquidityUSD
      volume: dailyVolumeUSD
      fees: dailyFeesUSD
    }
  }
`

import '@reach/dialog/styles.css'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import React, { StrictMode } from 'react'
import { hotjar } from 'react-hotjar'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { cacheExchange, Client, fetchExchange, Provider as UrqlProvider } from 'urql'
import { authExchange, AuthConfig } from '@urql/exchange-auth'
import Blocklist from './components/Blocklist'
import { NetworkContextName } from './constants/misc'
import { LanguageProvider } from './i18n'
import App from './pages/App'
import store from './state'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import TransactionUpdater from './state/transactions/updater'
import UserUpdater, { BaseCurrencyRatesUpdater } from './state/user/updater'
import PoolsUpdater from './state/pools/updater'
import WalletUpdater from './state/wallet/updater'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
import getLibrary from './utils/getLibrary'
import { getBasicNahmiiNetworkCredentials } from './utils'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

const SUBGRAPH_URL = window.__RUNTIME_CONFIG__.PUBLIC_SUBGRAPH_URL || ''

const { username, password } = getBasicNahmiiNetworkCredentials()

const graphClient = new Client({
  url: SUBGRAPH_URL,
  exchanges: [
    cacheExchange,
    authExchange(
      async (utils) =>
        ({
          addAuthToOperation: (operation) =>
            utils.appendHeaders(operation, {
              Authorization: `Basic ${btoa(`${username}:${password}`)}`,
            }),
        } as AuthConfig)
    ),
    fetchExchange,
  ],
})

const GOOGLE_ANALYTICS_ID: string | undefined = window.__RUNTIME_CONFIG__.GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      storage: 'none',
      storeGac: false,
    },
  })
  ReactGA.set({
    anonymizeIp: true,
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  })
} else {
  // ReactGA.initialize('test', { testMode: true, debug: true })
  ReactGA.initialize('test', { testMode: true })
}
const hotJarId = Number(window.__RUNTIME_CONFIG__.HOTJAR_KEY)
const hotJarVersion = Number(window.__RUNTIME_CONFIG__.HOTJAR_VERSION)

if (hotJarId && hotJarVersion && !isNaN(hotJarId) && !isNaN(hotJarVersion)) {
  console.log('initialize the hotjar')
  hotjar.initialize(hotJarId, hotJarVersion)
} else {
  console.error('HotJAR Id is not valid')
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <BaseCurrencyRatesUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <PoolsUpdater />
      <WalletUpdater />
    </>
  )
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <LanguageProvider>
          <UrqlProvider value={graphClient}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <Web3ProviderNetwork getLibrary={getLibrary}>
                <Blocklist>
                  <Updaters />
                  <ThemeProvider>
                    <ThemedGlobalStyle />
                    <App />
                  </ThemeProvider>
                </Blocklist>
              </Web3ProviderNetwork>
            </Web3ReactProvider>
          </UrqlProvider>
        </LanguageProvider>
      </HashRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

serviceWorkerRegistration.unregister()

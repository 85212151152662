import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { t } from '@lingui/macro'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import styled, { DefaultTheme } from 'styled-components'
import Percent from 'components/Percent'
import CurrencyAvatar from 'components/CurrencyAvatar'
import { TYPE, RowWrapper, BaseCurrencyView } from '../../theme'
import Table from './index'
import { useAllTokens } from 'hooks/useGraph'
import { OrderDir } from 'constants/queries'

const StyledTableRow = styled(TableRow)`
  td:first-child {
    padding-left: 2rem;
  }
  td:last-child {
    padding-right: 2rem;
  }
`

const CustomTableRow = (
  row: any,
  index: number,
  theme: DefaultTheme,
  handleClick: (event: React.MouseEvent<unknown>, name: string) => void
) => {
  const rowCellStyles = { color: theme.black, borderBottom: `1px solid ${theme.bg3}`, fontSize: '16px' }

  return (
    <StyledTableRow
      hover
      onClick={(event) => (row?.url ? window.open(row.url, '_blank') : handleClick(event, row.id))}
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      key={index}
      selected={false}
      style={{ cursor: 'pointer' }}
    >
      <TableCell style={rowCellStyles} align="left">
        {index + 1}
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <RowWrapper>
          <CurrencyAvatar
            symbol={row.symbol}
            address={row.address}
            iconProps={{ width: '30', height: '30' }}
            hideSymbol={true}
          />
          <TYPE.black fontWeight={400} style={{ padding: '8px 0 0 6px' }}>
            {row.symbol}
          </TYPE.black>
        </RowWrapper>
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="symbol" value={row.priceUSD} />
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <Percent isLeft={true} value={row.priceUSDChange} fontWeight={400} />
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="symbol" value={row.volumeUSD} />
      </TableCell>
      <TableCell style={rowCellStyles} align="left">
        <BaseCurrencyView type="symbol" value={row.tvlUSD} />
      </TableCell>
    </StyledTableRow>
  )
}

export default function OverviewTable() {
  const { state } = useLocation<any>() // FIXME: any
  const [fetchParams, setFetchParams] = React.useState<any>({
    orderBy: 'totalLiquidityUSD',
    orderDir: OrderDir.DESC,
    offset: 0,
    limit: 11,
  })

  const { data, fetching } = useAllTokens(fetchParams)

  useEffect(() => {
    if (state?.type !== undefined) {
      setFetchParams({
        orderBy: 'pricePctChange',
        orderDir: state?.type === 'looser' ? OrderDir.ASC : OrderDir.DESC,
        offset: 0,
        limit: 11,
      })
    } else {
      setFetchParams({
        orderBy: 'dailyVolumeUSD',
        orderDir: OrderDir.DESC,
        offset: 0,
        limit: 11,
      })
    }
  }, [state])

  return (
    <>
      {data && (
        <Table
          key={state?.type}
          title={t`Top Tokens`}
          data={data}
          fetching={fetching}
          headCells={[
            { id: '', numeric: true, disablePadding: false, label: '#' },
            { id: 'symbol', numeric: false, disablePadding: false, label: t`Name` },
            { id: 'priceUSD', numeric: true, disablePadding: false, label: t`Price` },
            { id: 'pricePctChange', numeric: true, disablePadding: false, label: t`Price Change` },
            { id: 'dailyVolumeUSD', numeric: true, disablePadding: false, label: t`Volume 24H` },
            { id: 'totalLiquidityUSD', numeric: true, disablePadding: false, label: t`TVL` },
          ]}
          row={CustomTableRow}
          defaultOrder={fetchParams.orderDir}
          defaultOrderBy={fetchParams.orderBy}
          setFetchParams={setFetchParams}
        />
      )}
    </>
  )
}

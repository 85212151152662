import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'

import { SupportedChainId } from '../constants/chains'
import getLibrary from '../utils/getLibrary'
import { NetworkConnector } from './NetworkConnector'
import { AddressMap } from 'constants/chains'
import { DEFAULT_CHAIN_ID } from 'constants/chains'

const INFURA_KEY = window.__RUNTIME_CONFIG__.INFURA_KEY

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`INFURA_KEY must be a defined environment variable`)
}

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [DEFAULT_CHAIN_ID]

export const NETWORK_URLS: AddressMap = {
  [DEFAULT_CHAIN_ID]: window.__RUNTIME_CONFIG__.RPC,
}

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: DEFAULT_CHAIN_ID,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

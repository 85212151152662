import { gql } from 'urql'

export const TX_PAIR_DATA_FIELDS = gql`
  fragment PairData on Pair {
    symbolId
    pool: id
    token0 {
      address: id
      symbol
    }
    token1 {
      address: id
      symbol
    }
  }
`

export const TOKEN_TOKEN_DATA_FIELDS = gql`
  fragment TokenData on Token {
    address: id
    symbol
    volumeUSD: tradeVolumeUSD
    tokenDayData(first: 1, orderBy: date, orderDirection: desc) {
      date
      priceUSD
      priceUSDChange: pricePctChange
      tvlUSD: totalLiquidityUSD
    }
  }
`

export const PAIR_PAIR_DATA_FIELDS = gql`
  fragment PairData on Pair {
    symbolId
    address: id
    liquidity: reserveUSD
    token0 {
      address: id
      decimals
      symbol
      trading: tradeVolumeUSD
      mcap: marketCapUSD
    }
    token1 {
      address: id
      decimals
      symbol
      trading: tradeVolumeUSD
      mcap: marketCapUSD
    }
    token0Price: token1Price
    token1Price: token0Price
    apy
    niiApy
    niifiApy
    totalApy
  }
`

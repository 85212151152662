import React, { useState, useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { Trans } from '@lingui/macro'
import { TYPE, BaseCurrencyView } from '../../theme'
import LineChart from './index'
import { useDailyChartStats } from 'hooks/useGraph'
import { dateToChartDate } from 'utils/timestamps'

const OverviewChart = () => {
  const theme = useContext(ThemeContext)
  const [amount, setAmount] = useState<number | undefined>()
  const [time, setTime] = useState<string | undefined>()
  const [lineChartData, setLineChartData] = useState<any[] | undefined>([])

  const { data: stats } = useDailyChartStats({ range: 'week' })

  useEffect(() => {
    if (!stats || !stats.length) return
    setLineChartData(stats)
  }, [stats])

  useEffect(() => {
    if (!time && lineChartData && lineChartData.length) {
      setTime(dateToChartDate(lineChartData[lineChartData.length - 1].time))
    }
  }, [time, lineChartData])

  useEffect(() => {
    if (!amount && lineChartData && lineChartData.length) {
      setAmount(lineChartData[lineChartData.length - 1].tvl)
    }
  }, [amount, lineChartData])

  return (
    <>
      <TYPE.subHeader fontSize="16px" style={{ margin: '12px 0 6px 0' }}>
        <Trans>TVL History</Trans>
      </TYPE.subHeader>
      <TYPE.mediumHeader>
        {(amount && !isNaN(amount) && <BaseCurrencyView type="symbol" value={amount} />) || '-'}
      </TYPE.mediumHeader>
      <TYPE.body color={theme.text5} fontWeight={400} fontSize={14} lineHeight={1.4}>
        {time || '-'}
      </TYPE.body>
      {lineChartData && (
        <LineChart
          data={lineChartData}
          minHeight={165}
          value1={amount}
          setValue1={setAmount}
          currentValue="tvl"
          value1Name={'tvl'}
          dateFormat="DD"
          label={time}
          setLabel={setTime}
          YAxisTick={false}
          style={{ flexDirection: 'column' }}
        />
      )}
    </>
  )
}

export default OverviewChart

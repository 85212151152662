import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { AutoRow } from 'components/Row'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga4'
import styled from 'styled-components/macro'
import MetamaskIcon from '../../assets/images/metamask.png'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { NETWORK_URLS, injected } from '../../connectors'
import { DEFAULT_CHAIN_ID } from 'constants/chains'
import { SUPPORTED_CHAIN_NAMES } from 'constants/chains'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import { ExternalLink, TYPE } from '../../theme'
import { Trans } from '@lingui/macro'

import Modal from '../Modal'
import Option from './Option'
import PendingView from './PendingView'
import { LightCard } from '../Card'
import { ButtonPrimary } from 'components/Button'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { BRIDGE_MAP } from 'constants/chains'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const Title = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
  user-select: none;
`

export default function WalletModal() {
  // important that these are destructed from the account-specific web3-react context
  const { account, connector, activate, error } = useWeb3React()

  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()

  const [pendingError, setPendingError] = useState<boolean>()

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  // close on connection, when logged out before
  useEffect(() => {
    if (account && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal, walletModalOpen])

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false)
      setPendingWallet(undefined)
    }
  }, [walletModalOpen])

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = ''
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Change Wallet',
      label: name,
    })
    setPendingWallet(connector) // set wallet for pending view

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector) // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true)
        }
      })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]
      // check for mobile options
      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={<Trans>Install Metamask</Trans>}
                subheader={null}
                link={`https://metamask.app.link/dapp/${window.location.host}`}
                icon={MetamaskIcon}
              />
            )
          }
        }
        if (option.mobile && option.name === 'MetaMask') {
          return (
            <Option
              id={`connect-${key}`}
              onClick={() => !option.href && tryActivation(option.connector)}
              key={key}
              active={option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null} //use option.descriptio to bring back multi-line
              icon={option.iconURL}
            />
          )
        }
        return null
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={<Trans>Install Metamask</Trans>}
                subheader={null}
                link={`https://metamask.app.link/dapp/${window.location.host}`}
                icon={MetamaskIcon}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => !option.href && tryActivation(option.connector)}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={option.iconURL}
          />
        )
      )
    })
  }

  async function handleSwitchNetwork() {
    const chainID = '0x' + Number(DEFAULT_CHAIN_ID).toString(16)
    const ethereum = window.ethereum as any
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainID }],
      })
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      // Desktop || Mobile
      if (switchError.code === 4902 || switchError.data?.originalError?.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainID,
                chainName: SUPPORTED_CHAIN_NAMES[DEFAULT_CHAIN_ID as keyof typeof SUPPORTED_CHAIN_NAMES],
                rpcUrls: [NETWORK_URLS[DEFAULT_CHAIN_ID as keyof typeof SUPPORTED_CHAIN_NAMES]],
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18,
                },
                blockExplorerUrls: [getExplorerLink(DEFAULT_CHAIN_ID, '', ExplorerDataType.BASE)],
              },
            ],
          })
        } catch (addError: any) {
          // handle "add" error
          console.error(addError)
        }
      }
    }
  }

  function getModalContent() {
    if (error) {
      return (
        <UpperSection>
          <CloseIcon onClick={toggleWalletModal}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>
            {error instanceof UnsupportedChainIdError ? <Trans>Wrong Network</Trans> : <Trans>Error connecting</Trans>}
          </HeaderRow>
          <ContentWrapper>
            {error instanceof UnsupportedChainIdError ? (
              <div>
                <h5>
                  <Trans>Please connect to the {SUPPORTED_CHAIN_NAMES[DEFAULT_CHAIN_ID]} network.</Trans>
                  <br />
                  <br />
                  <Trans>
                    To use NiiFi you need to deposit funds into Nahmii. Please visit the{' '}
                    <a href={BRIDGE_MAP[DEFAULT_CHAIN_ID]} target="_blank" rel="noreferrer">
                      Nahmii Account Page
                    </a>
                    {'. '}
                  </Trans>
                </h5>
                <br />
                <ButtonPrimary onClick={handleSwitchNetwork}> Switch Network </ButtonPrimary>
              </div>
            ) : (
              <Trans>Error connecting. Try refreshing the page.</Trans>
            )}
          </ContentWrapper>
        </UpperSection>
      )
    }
    if (account) {
      return null
    }
    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <Title>
            <Trans>Connect wallet</Trans>
          </Title>
        </HeaderRow>

        <ContentWrapper>
          <LightCard style={{ marginBottom: '16px' }}>
            <AutoRow style={{ flexWrap: 'nowrap' }}>
              <TYPE.main fontSize={14}>
                <Trans>
                  By connecting a wallet, you agree to NiiFi{` `}
                  <ExternalLink href="http://example.com">Terms of Service(coming soon)</ExternalLink>
                </Trans>
              </TYPE.main>
            </AutoRow>
          </LightCard>
          {pendingWallet ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper data-test="wallet-modal">{getModalContent()}</Wrapper>
    </Modal>
  )
}

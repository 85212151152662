import React, { useRef, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { CheckCircle, ChevronDown, ExternalLink } from 'react-feather'
import styled from 'styled-components'

import { useUserWallets } from 'state/user/hooks'
import { ConnectedSign, IdenticonWrapper } from './WalletList'
import WalletModal from '../WalletModal'
import { useActiveWeb3React } from '../../hooks/web3'
import { UserWalletTypes } from 'state/user/actions'
import Menu from '../Menu'
import { CopyIcon, RemoveIcon } from '../Icons'
import useTheme from 'hooks/useTheme'
import Identicon from 'components/Identicon'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { WalletConnect } from 'components/WalletConnect'
import { shortenAddress } from 'utils'
import useCopyClipboard from 'hooks/useCopyClipboard'

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  &:focus-visible {
    outline: none;
  }
`

const MenuItem = styled.button`
  display: flex;
  padding: 1rem 0.5rem;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.text1};
  :hover,
  :focus {
    font-weight: 400;
    background-color: ${({ theme }) => theme.bg5};
  }
`

const ControlWrapper = styled.div<{ open: boolean }>`
  cursor: pointer;
  width: content-fit;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${({ open, theme }) => (open ? theme.bg5 : '')};
  border-radius: 8px;
  :hover {
    background-color: ${({ theme }) => theme.bg5};
    border-radius: 8px;
  }
`

const ControlButton = styled.div`
  display: flex;
  padding: 0.5rem;
  font-size: 1rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 0;
`

const CopyMenuItem = styled(MenuItem)`
  &.copied {
    color: ${({ theme }) => theme.primary1};
  }
`

function CopyHelper(props: { toCopy: string; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <CopyMenuItem
      className={isCopied ? 'copied' : ''}
      onClick={(e) => {
        e.stopPropagation()
        setCopied(props.toCopy)
      }}
    >
      {isCopied ? (
        <>
          <CheckCircle size={'20'} />
          <Trans>Copied</Trans>
        </>
      ) : (
        <CopyIcon />
      )}
      {isCopied ? '' : props.children}
    </CopyMenuItem>
  )
}

const useAccountChange = () => {
  const { account } = useActiveWeb3React()
  const { addNewUserWallet } = useUserWallets()
  useEffect(() => {
    if (account) {
      const timeOut: number = window.setTimeout(() => {
        addNewUserWallet({ address: account.toLowerCase(), type: UserWalletTypes.CONNECTED })
      })
      return () => {
        window.clearTimeout(timeOut)
      }
    }
    return undefined
  }, [account, addNewUserWallet])
}
export default function WalletPopover() {
  const theme = useTheme()
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState<boolean>(false)
  const { chainId, deactivate, account } = useActiveWeb3React()

  useAccountChange()
  const handleClose = () => {
    setOpen(false)
  }
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <>
      {account ? (
        <ControlWrapper ref={anchorRef} open={open} onClick={handleClick}>
          <ControlButton>
            <IdenticonWrapper>
              <Identicon address={account} />
              <ConnectedSign />
            </IdenticonWrapper>
            {shortenAddress(account)}
            <ChevronDown size="30px" color={theme.black} />
          </ControlButton>
          <Menu
            style={{ width: '100%', paddingRight: '0.75rem', borderRadius: '12px' }}
            id="WalletMenu"
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleClose}
            placement="bottom-end"
          >
            <MenuWrapper>
              <CopyHelper toCopy={account.toLowerCase()}>
                <Trans>Copy Address</Trans>
              </CopyHelper>
              <MenuItem
                onClick={() =>
                  chainId &&
                  window.open(getExplorerLink(chainId, account.toLowerCase(), ExplorerDataType.ADDRESS), '_blank')
                }
              >
                <ExternalLink />
                <Trans>View on Explorer</Trans>
              </MenuItem>
              <MenuItem onClick={deactivate}>
                <RemoveIcon />
                <Trans>Disconnect</Trans>
              </MenuItem>
            </MenuWrapper>
          </Menu>
        </ControlWrapper>
      ) : (
        <WalletConnect />
      )}
      <WalletModal />
    </>
  )
}

import React, { ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import Tooltip from '../Tooltip'
import { InfoIcon } from 'components/Icons'

const InfoWrapper = styled.div`
  color: ${({ theme }) => theme.text4};

  :hover,
  :focus {
    opacity: 0.8;
  }
`

const TooltipWrapper = styled.div`
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
`

export default function InfoHelper({ text, size }: { text: ReactNode; size?: number }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip text={text} show={show} width={size ? size + 'px' : undefined} WrapperElement={TooltipWrapper}>
        <InfoWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <InfoIcon width="1.125rem" height="1.125rem" />
        </InfoWrapper>
      </Tooltip>
    </span>
  )
}
